import IDictionary from "./interfaces/IDictionary";

export var TipoEmail = [
  { value: 0, text: 'Pessoal' },
  { value: 1, text: 'Comercial' }
]

export var TipoTelefone = [
  { value: 0, text: 'Comercial' },
  { value: 1, text: 'Residencial' },
  { value: 2, text: 'Celular' }
]

export var TipoEndereco = [
  { value: 0, text: 'Comercial' },
  { value: 1, text: 'Residencial' }
]

export var Sexo = [
  { value: 0, text: 'Masculino' },
  { value: 1, text: 'Feminino' }
]

export var EstadoCivil = [
  { value: 0, text: 'Solteiro' },
  { value: 1, text: 'Casado' },
  { value: 2, text: 'Viúvo' },
  { value: 3, text: 'Divorciado' }
];

export var Escolaridade = [
  { value: 0, text: 'Sem Instrução' },
  { value: 1, text: 'Ensino Fundamental' },
  { value: 2, text: 'Ensino Médio' },
  { value: 3, text: 'Graduação' },
  { value: 4, text: 'Pós Graduação' },
  { value: 5, text: 'Mestrado' },
  { value: 6, text: 'Doutorado' },
  { value: 7, text: 'PHD' }
];

export var Raca = [
  { value: 0, text: 'Amarela' },
  { value: 1, text: 'Branca' },
  { value: 2, text: 'Preta' },
  { value: 3, text: 'Parda' },
  { value: 4, text: 'Indígena' },
];

export const Estado: Array<IDictionary> = [
  { value: 0, text: 'Não Informado' },
  { value: 1, text: 'Acre' },
  { value: 2, text: 'Alagoas' },
  { value: 3, text: 'Amapá' },
  { value: 4, text: 'Amazonas' },
  { value: 5, text: 'Bahia' },
  { value: 6, text: 'Ceará' },
  { value: 7, text: 'Distrito Federal' },
  { value: 8, text: 'Espírito Santo' },
  { value: 9, text: 'Goiás' },
  { value: 10, text: 'Maranhão' },
  { value: 11, text: 'Mato Grosso' },
  { value: 12, text: 'Mato Grosso do Sul' },
  { value: 13, text: 'Minas Gerais' },
  { value: 14, text: 'Pará' },
  { value: 15, text: 'Paraíba' },
  { value: 16, text: 'Paraná' },
  { value: 17, text: 'Pernambuco' },
  { value: 18, text: 'Piauí' },
  { value: 19, text: 'Rio de Janeiro' },
  { value: 20, text: 'Rio Grande do Norte' },
  { value: 21, text: 'Rio Grande do Sul' },
  { value: 22, text: 'Rondônia' },
  { value: 23, text: 'Roraima' },
  { value: 24, text: 'Santa Catarina' },
  { value: 25, text: 'São Paulo' },
  { value: 26, text: 'Sergipe' },
  { value: 27, text: 'Tocantins' },
];


export var SiglaUf = [
  { value: 0, text: 'Não Informado' },
  { value: 1, text: 'AC' },
  { value: 2, text: 'AL' },
  { value: 3, text: 'AP' },
  { value: 4, text: 'AM' },
  { value: 5, text: 'BA' },
  { value: 6, text: 'CE' },
  { value: 7, text: 'DF' },
  { value: 8, text: 'ES' },
  { value: 9, text: 'GO' },
  { value: 10, text: 'MA' },
  { value: 11, text: 'MT' },
  { value: 12, text: 'MS' },
  { value: 13, text: 'MG' },
  { value: 14, text: 'PA' },
  { value: 15, text: 'PB' },
  { value: 16, text: 'PR' },
  { value: 17, text: 'PE' },
  { value: 18, text: 'PI' },
  { value: 19, text: 'RJ' },
  { value: 20, text: 'RN' },
  { value: 21, text: 'RS' },
  { value: 22, text: 'RO' },
  { value: 23, text: 'RR' },
  { value: 24, text: 'SC' },
  { value: 25, text: 'SP' },
  { value: 26, text: 'SE' },
  { value: 27, text: 'TO' },
];

export var Pais = [
  { value: 0, text: 'Albânia' },
  { value: 1, text: 'Alemanha' },
  { value: 2, text: 'Andorra' },
  { value: 3, text: 'Angola' },
  { value: 4, text: 'Anguilla' },
  { value: 5, text: 'Antártida' },
  { value: 6, text: 'Antígua e Barbuda' },
  { value: 7, text: 'Antilhas Holandesas' },
  { value: 8, text: 'Arábia Saudita' },
  { value: 9, text: 'Argélia' },
  { value: 10, text: 'Argentina' },
  { value: 11, text: 'Armênia' },
  { value: 12, text: 'Aruba' },
  { value: 13, text: 'Austrália' },
  { value: 14, text: 'Áustria' },
  { value: 15, text: 'Azerbaijão' },
  { value: 16, text: 'Bahamas' },
  { value: 17, text: 'Bahrein' },
  { value: 18, text: 'Bangladesh' },
  { value: 19, text: 'Barbados' },
  { value: 20, text: 'Belarus' },
  { value: 21, text: 'Bélgica' },
  { value: 22, text: 'Belize' },
  { value: 23, text: 'Benin' },
  { value: 24, text: 'Bermudas' },
  { value: 25, text: 'Bolívia' },
  { value: 26, text: 'Bósnia-Herzegóvina' },
  { value: 27, text: 'Botsuana' },
  { value: 28, text: 'Brasil' },
  { value: 29, text: 'Brunei' },
  { value: 30, text: 'Bulgária' },
  { value: 31, text: 'Burkina Fasso' },
  { value: 32, text: 'Burundi' },
  { value: 33, text: 'Butão' },
  { value: 34, text: 'Cabo Verde' },
  { value: 35, text: 'Camarões' },
  { value: 36, text: 'Camboja' },
  { value: 37, text: 'Canadá' },
  { value: 38, text: 'Cazaquistão' },
  { value: 39, text: 'Chade' },
  { value: 40, text: 'Chile' },
  { value: 41, text: 'China' },
  { value: 42, text: 'Chipre' },
  { value: 43, text: 'Cingapura' },
  { value: 44, text: 'Colômbia' },
  { value: 45, text: 'Congo' },
  { value: 46, text: 'Coréia do Norte' },
  { value: 47, text: 'Coréia do Sul' },
  { value: 48, text: 'Costa do Marfim' },
  { value: 49, text: 'Costa Rica' },
  { value: 50, text: 'Croácia (Hrvatska)' },
  { value: 51, text: 'Cuba' },
  { value: 52, text: 'Dinamarca' },
  { value: 53, text: 'Djibuti' },
  { value: 54, text: 'Dominica' },
  { value: 55, text: 'Egito' },
  { value: 56, text: 'El Salvador' },
  { value: 57, text: 'Emirados Árabes Unidos' },
  { value: 58, text: 'Equador' },
  { value: 59, text: 'Eritréia' },
  { value: 60, text: 'Eslováquia' },
  { value: 61, text: 'Eslovênia' },
  { value: 62, text: 'Espanha' },
  { value: 63, text: 'Estados Unidos' },
  { value: 64, text: 'Estônia' },
  { value: 65, text: 'Etiópia' },
  { value: 66, text: 'Fiji' },
  { value: 67, text: 'Filipinas' },
  { value: 68, text: 'Finlândia' },
  { value: 69, text: 'França' },
  { value: 70, text: 'Gabão' },
  { value: 71, text: 'Gâmbia' },
  { value: 72, text: 'Gana' },
  { value: 73, text: 'Geórgia' },
  { value: 74, text: 'Gibraltar' },
  { value: 75, text: 'Grã-Bretanha (Reino Unido, UK)' },
  { value: 76, text: 'Granada' },
  { value: 77, text: 'Grécia' },
  { value: 78, text: 'Groelândia' },
  { value: 79, text: 'Guadalupe' },
  { value: 80, text: 'Guam (Território dos Estados Unidos)' },
  { value: 81, text: 'Guatemala' },
  { value: 82, text: 'Guernsey' },
  { value: 83, text: 'Guiana' },
  { value: 84, text: 'Guiana Francesa' },
  { value: 85, text: 'Guiné' },
  { value: 86, text: 'Guiné Equatorial' },
  { value: 87, text: 'Guiné-Bissau' },
  { value: 88, text: 'Haiti' },
  { value: 89, text: 'Holanda' },
  { value: 90, text: 'Honduras' },
  { value: 91, text: 'Hong Kong' },
  { value: 92, text: 'Hungria' },
  { value: 93, text: 'Iêmen' },
  { value: 94, text: 'Ilha Bouvet (Território da Noruega)' },
  { value: 95, text: 'Ilha do Homem' },
  { value: 96, text: 'Ilha Natal' },
  { value: 97, text: 'Ilha Pitcairn' },
  { value: 98, text: 'Ilha Reunião' },
  { value: 99, text: 'Ilhas Aland' },
  { value: 100, text: 'Ilhas Cayman' },
  { value: 101, text: 'Ilhas Cocos' },
  { value: 102, text: 'Ilhas Comores' },
  { value: 103, text: 'Ilhas Cook' },
  { value: 104, text: 'Ilhas Faroes' },
  { value: 105, text: 'Ilhas Falkland (Malvinas)' },
  { value: 106, text: 'Ilhas Geórgia do Sul e Sandwich do Sul' },
  { value: 107, text: 'Ilhas Heard e McDonald (Território da Austrália)' },
  { value: 108, text: 'Ilhas Marianas do Norte' },
  { value: 109, text: 'Ilhas Marshall' },
  { value: 110, text: 'Ilhas Menores dos Estados Unidos' },
  { value: 111, text: 'Ilhas Norfolk' },
  { value: 112, text: 'Ilhas Seychelles' },
  { value: 113, text: 'Ilhas Solomão' },
  { value: 114, text: 'Ilhas Svalbard e Jan Mayen' },
  { value: 115, text: 'Ilhas Tokelau' },
  { value: 116, text: 'Ilhas Turks e Caicos' },
  { value: 117, text: 'Ilhas Virgens (Estados Unidos)' },
  { value: 118, text: 'Ilhas Virgens (Inglaterra)' },
  { value: 119, text: 'Ilhas Wallis e Futuna' },
  { value: 120, text: 'índia' },
  { value: 121, text: 'Indonésia' },
  { value: 122, text: 'Irã' },
  { value: 123, text: 'Iraque' },
  { value: 124, text: 'Irlanda' },
  { value: 125, text: 'Islândia' },
  { value: 126, text: 'Israel' },
  { value: 127, text: 'Itália' },
  { value: 128, text: 'Jamaica' },
  { value: 129, text: 'Japão' },
  { value: 130, text: 'Jersey' },
  { value: 131, text: 'Jordânia' },
  { value: 132, text: 'Kênia' },
  { value: 133, text: 'Kiribati' },
  { value: 134, text: 'Kuait' },
  { value: 135, text: 'Laos' },
  { value: 136, text: 'Látvia' },
  { value: 137, text: 'Lesoto' },
  { value: 138, text: 'Líbano' },
  { value: 139, text: 'Libéria' },
  { value: 140, text: 'Líbia' },
  { value: 141, text: 'Liechtenstein' },
  { value: 142, text: 'Lituânia' },
  { value: 143, text: 'Luxemburgo' },
  { value: 144, text: 'Macau' },
  { value: 145, text: 'Macedônia (República Yugoslava)' },
  { value: 146, text: 'Madagascar' },
  { value: 147, text: 'Malásia' },
  { value: 148, text: 'Malaui' },
  { value: 149, text: 'Maldivas' },
  { value: 150, text: 'Mali' },
  { value: 151, text: 'Malta' },
  { value: 152, text: 'Marrocos' },
  { value: 153, text: 'Martinica' },
  { value: 154, text: 'Maurício' },
  { value: 155, text: 'Mauritânia' },
  { value: 156, text: 'Mayotte' },
  { value: 157, text: 'México' },
  { value: 158, text: 'Micronésia' },
  { value: 159, text: 'Moçambique' },
  { value: 160, text: 'Moldova' },
  { value: 161, text: 'Mônaco' },
  { value: 162, text: 'Mongólia' },
  { value: 163, text: 'Montenegro' },
  { value: 164, text: 'Montserrat' },
  { value: 165, text: 'Myanma' },
  { value: 166, text: 'Namíbia' },
  { value: 167, text: 'Nauru' },
  { value: 168, text: 'Nepal' },
  { value: 169, text: 'Nicarágua' },
  { value: 170, text: 'Níger' },
  { value: 171, text: 'Nigéria' },
  { value: 172, text: 'Niue' },
  { value: 173, text: 'Noruega' },
  { value: 174, text: 'Nova Caledônia' },
  { value: 175, text: 'Nova Zelândia' },
  { value: 176, text: 'Omã' },
  { value: 177, text: 'Palau' },
  { value: 178, text: 'Panamá' },
  { value: 179, text: 'Papua-Nova Guiné' },
  { value: 180, text: 'Paquistão' },
  { value: 181, text: 'Paraguai' },
  { value: 182, text: 'Peru' },
  { value: 183, text: 'Polinésia Francesa' },
  { value: 184, text: 'Polônia' },
  { value: 185, text: 'Porto Rico' },
  { value: 186, text: 'Portugal' },
  { value: 187, text: 'Qatar' },
  { value: 188, text: 'Quirguistão' },
  { value: 189, text: 'República Centro-Africana' },
  { value: 190, text: 'República Democrática do Congo' },
  { value: 191, text: 'República Dominicana' },
  { value: 192, text: 'República Tcheca' },
  { value: 193, text: 'Romênia' },
  { value: 194, text: 'Ruanda' },
  { value: 195, text: 'Rússia (antiga URSS) - Federação Russa' },
  { value: 196, text: 'Saara Ocidental' },
  { value: 197, text: 'Saint Vincente e Granadinas' },
  { value: 198, text: 'Samoa Americana' },
  { value: 199, text: 'Samoa Ocidental' },
  { value: 200, text: 'San Marino' },
  { value: 201, text: 'Santa Helena' },
  { value: 202, text: 'Santa Lúcia' },
  { value: 203, text: 'São Bartolomeu' },
  { value: 204, text: 'São Cristóvão e Névis' },
  { value: 205, text: 'São Martim' },
  { value: 206, text: 'São Tomé e Príncipe' },
  { value: 207, text: 'Senegal' },
  { value: 208, text: 'Serra Leoa' },
  { value: 209, text: 'Sérvia' },
  { value: 210, text: 'Síria' },
  { value: 211, text: 'Somália' },
  { value: 212, text: 'Sri Lanka' },
  { value: 213, text: 'St. Pierre and Miquelon' },
  { value: 214, text: 'Suazilândia' },
  { value: 215, text: 'Sudão' },
  { value: 216, text: 'Suécia' },
  { value: 217, text: 'Suíça' },
  { value: 218, text: 'Suriname' },
  { value: 219, text: 'Tadjiquistão' },
  { value: 220, text: 'Tailândia' },
  { value: 221, text: 'Taiwan' },
  { value: 222, text: 'Tanzânia' },
  { value: 223, text: 'Território Britânico do Oceano índico' },
  { value: 224, text: 'Territórios do Sul da França' },
  { value: 225, text: 'Territórios Palestinos Ocupados' },
  { value: 226, text: 'Timor Leste' },
  { value: 227, text: 'Togo' },
  { value: 228, text: 'Tonga' },
  { value: 229, text: 'Trinidad and Tobago' },
  { value: 230, text: 'Tunísia' },
  { value: 231, text: 'Turcomenistão' },
  { value: 232, text: 'Turquia' },
  { value: 233, text: 'Tuvalu' },
  { value: 234, text: 'Ucrânia' },
  { value: 235, text: 'Uganda' },
  { value: 236, text: 'Uruguai' },
  { value: 237, text: 'Uzbequistão' },
  { value: 238, text: 'Vanuatu' },
  { value: 239, text: 'Vaticano' },
  { value: 240, text: 'Venezuela' },
  { value: 241, text: 'Vietnã' },
  { value: 242, text: 'Zâmbia' },
  { value: 243, text: 'Zimbábue' }
];

export var Especialidade = [
  { value: 0, text: 'Não Informado' },
  { value: 1, text: 'Clinico Geral' },
  { value: 2, text: 'Gastroenterologia' },
  { value: 3, text: 'Endocrinologia' },
  { value: 4, text: 'Angiologia' },
  { value: 5, text: 'Cardiologia' },
  { value: 6, text: 'Dermatologia' },
  { value: 7, text: 'Geriatria' },
  { value: 8, text: 'Ginecologia e Obstetrícia' },
  { value: 9, text: 'Hematologia' },
  { value: 10, text: 'Homeopatia' },
  { value: 11, text: 'Infectologia' },
  { value: 12, text: 'Nefrologia' },
  { value: 13, text: 'Oftalmologia' },
  { value: 14, text: 'Ortopedia' },
  { value: 15, text: 'Otorrinolaringologia' },
  { value: 16, text: 'Pediatria' },
  { value: 17, text: 'Pneumologia' },
  { value: 18, text: 'Proctologia' },
  { value: 19, text: 'Psiquiatria' },
  { value: 20, text: 'Reumatologia' },
  { value: 21, text: 'Urologia' },
]

export var TipoPessoa = [
  { value: 0, text: 'Pessoa Física' },
  { value: 1, text: 'Pessoa Jurídica' }
]

export var TipoVisualizacao = [
  { value: 1, text: 'Mês' },
  { value: 2, text: 'Semana' },
  { value: 3, text: 'Dia' }
]

export var SituacaoAgendamento = [
  { value: 0, text: 'Não Informado' },
  { value: 1, text: 'Em Espera' },
  { value: 2, text: 'Faltou' },
  { value: 3, text: 'Finalizado' },
  { value: 4, text: 'Cancelado' }
]

export var TipoAviso = [
  { value: 0, text: 'Não Informado' },
  { value: 1, text: 'Ao Agendar Antes' },
  { value: 2, text: 'Antes' },
  { value: 3, text: 'Ao Agendar' }
]

export var DiaSemana = [
  { value: 1, text: 'Todos' },
  { value: 2, text: 'Segunda' },
  { value: 3, text: 'Terça' },
  { value: 4, text: 'Quarta' },
  { value: 5, text: 'Quinta' },
  { value: 6, text: 'Sexta' },
  { value: 7, text: 'Sabado' },
  { value: 0, text: 'Domingo' },
]

export var TipoConsultaRetorno = [
  { value: 0, text: 'Não Informado' },
  { value: 1, text: 'Consulta' },
  { value: 2, text: 'Retorno' },
]

export var SituacaoConfiguracao = [
  { value: 0, text: 'Não Informado' },
  { value: 1, text: 'Marcado' },
  { value: 2, text: 'Faltou' },
  { value: 3, text: 'Cancelado' },
]

export var Acao = [  
  { value: 0, text: 'Aplicar' },
  { value: 1, text: 'Aspirar' },
  { value: 2, text: 'Inalar' },
  { value: 3, text: 'Ingerir' },
  { value: 4, text: 'Injetar' },
  { value: 5, text: 'Passar' },
  { value: 6, text: 'Tomar' },
]

export var Frequencia = [    
    { value: 0, text: 'de hora em hora' },
    { value: 1, text: 'de 2 em 2 horas' },
    { value: 2, text: 'de 3 em 3 horas' },
    { value: 3, text: 'de 4 em 4 horas' },
    { value: 4, text: 'de 6 em 6 horas' },
    { value: 5, text: 'de 8 em 8 horas' },
    { value: 6, text: 'de 12 em 12 horas' },
    { value: 7, text: '1 vez ao dia' },
    { value: 8, text: '2 vezes ao dia' },
    { value: 9, text: '3 vezes ao dia' },
    { value: 10, text: '4 vezes ao dia' },
    { value: 11, text: '5 vezes ao dia' },
]

export var TipoModelo = [
    { value: 0, text: 'Encaminhamento' },
    { value: 1, text: 'Laudo' },
    { value: 2, text: 'Rotina' },
    { value: 3, text: 'Prescricao' }
]

export var LogAcao = [
    { value: 0, text: 'Todos' },
    { value: 1, text: 'Criação' },
    { value: 2, text: 'Edição' },
    { value: 3, text: 'Exclusão' },
    { value: 4, text: 'Logou' },
    { value: 5, text: 'Saiu' },
    { value: 6, text: 'Erro' },
    { value: 7, text: 'Warning' },
    { value: 8, text: 'Confirmou' },
    { value: 9, text: 'Enviou' },
    { value: 10, text: 'Importou' }    
]

export var LogRecurso = [
    { value: 1, text: 'Todos' },
    { value: 2, text: 'Agenda' },
    { value: 3, text: 'Agendamento' },
    { value: 4, text: 'Arquivo' },
    { value: 5, text: 'Atendimento' },
    { value: 6, text: 'Bloqueio de Horário' },
    { value: 7, text: 'CID' },
    { value: 8, text: 'Clínica' },
    { value: 9, text: 'Configuração de Aviso' },
    { value: 10, text: 'Consulta' },
    { value: 11, text: 'Convênio' },
    { value: 12, text: 'Encaminhamento' },
    { value: 13, text: 'Feriado' },
    { value: 14, text: 'Horário' },
    { value: 15, text: 'Laudo' },
    { value: 16, text: 'Medicamento' },
    { value: 17, text: 'Modelo' },
    { value: 18, text: 'Nota do Dia' },
    { value: 19, text: 'Paciente' },
    { value: 20, text: 'Plano' },
    { value: 21, text: 'Prescrição' },
    { value: 22, text: 'Rotina' },
    { value: 23, text: 'Tenant' },
    { value: 24, text: 'Tipo de Consulta' },
    { value: 25, text: 'Usuário' },
    { value: 26, text: 'Email' },
    { value: 27, text: 'Triagem' },
    { value: 28, text: 'Impresso' },
]

export var TipoImpresso = [    
    { value: 0, text: 'Agendamentos' },
    { value: 1, text: 'Atendimentos' },
    { value: 2, text: 'Clínicas' },
    { value: 3, text: 'Consultas' },
    { value: 4, text: 'Convênios' },
    { value: 5, text: 'Encaminhamentos' },
    { value: 6, text: 'Laudos' },
    { value: 7, text: 'Pacientes' },
    { value: 8, text: 'Planos' },
    { value: 9, text: 'Prescrições' },
    { value: 10, text: 'Rotinas' },
    { value: 11, text: 'Triagens' },
]