import Pacientes from "../pacientes/Pacientes";
import Usuario from "../usuario/Usuario";

export default class Calendario{
    public id: number;
    public name: string;
    public start: string;
    public tipoAgendamento: string;
    public dataAgendamento: string;
    public horaAgendamento: string;
    public duracaoAgendamento: string;
    public situacaoAgendamento: string;
    public observacao: string;
    public end: string;
    public color: string;
    public usuario: Usuario;    
    public paciente: Pacientes;
    public agendamentoConfirmado: boolean;

    constructor() {
        this.id = 0;
        this.name = '';
        this.start = '';
        this.tipoAgendamento = '';
        this.dataAgendamento = '';
        this.horaAgendamento = '';
        this.duracaoAgendamento = '';
        this.situacaoAgendamento = '';
        this.observacao = '';
        this.end = '';
        this.color = '';
        this.usuario = new Usuario();       
        this.paciente = new Pacientes(); 
        this.agendamentoConfirmado = false;
    }
}      
       