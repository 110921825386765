import AgendaUsuario from "../agenda/AgendaUsuario";
import DadosMedico from "../objetosDeValor/DadosMedico";

export default class Usuario{
    public nome: string;
    public sobrenome: string;
    public userName: string;
    public email: string;
    public password: string;
    public role: string;
    public listaAgendasUsuarios: string;
    public file?: File;
    public foto: string; 
    public dadosMedico: DadosMedico;
    public ativo: boolean;

    constructor() {
        this.nome = '';
        this.sobrenome = '';
        this.userName = '';
        this.email = '';
        this.password = '';
        this.role = '';
        this.foto = '';    
        this.dadosMedico = new DadosMedico();    
        this.listaAgendasUsuarios = '';
        this.ativo = true;
    }
}