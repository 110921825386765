<template>
    <div titulo="Cadastro de Pacientes" :loading="carregando ? 'success' : null" :disabled="carregando">
        <v-card>
            <v-card-title>{{ titulo }}</v-card-title>
            <validation-observer ref="observer" v-slot="{}">
                <form ref="form" @submit.prevent="salvar">
                    <v-col md="11" sm="12" xs="12" class="d-flex justify-md-end avatar">
                        <soi-avatar-input v-model="paciente.file" :fotoKey.sync="paciente.foto" @apagarFoto="apagarFoto"
                            :disabled="visualizar"></soi-avatar-input>
                    </v-col>
                    <v-card-text>
                        <h3>Dados Pessoais</h3>
                        <soi-dados-padrao-paciente v-model="paciente"
                            :disabled="visualizar"></soi-dados-padrao-paciente>
                        <soi-endereco v-model="paciente.endereco" :disabled="visualizar"></soi-endereco>
                        <soi-contato v-model="paciente.contato" :disabled="visualizar"></soi-contato>
                        <soi-pacienteconvenio v-model="pacienteConvenioPrincipal" titulo="Convênio Principal"
                            :disabled="visualizar"></soi-pacienteconvenio>
                        <soi-pacienteconvenio v-model="pacienteConvenioSecundario" titulo="Convênio Secundário"
                            :disabled="visualizar"></soi-pacienteconvenio>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-if="!visualizar" class="ml-2 botao-salvar" color="success" type="submit"
                                :loading="carregando" :disabled="carregando">
                                <v-icon left>$saveIcon</v-icon> Salvar
                            </v-btn>
                        </v-card-actions>
                    </v-card-text>
                </form>
            </validation-observer>
        </v-card>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import Pacientes from "../../models/pacientes/Pacientes";
import { PacientesService } from "../../services/PacientesService";
import { Sexo, Escolaridade, EstadoCivil, Raca } from "../../models/Enums";
import DateHelper from "../../helpers/DateHelper";
import MaskHelper from "../../helpers/MaskHelper";
import { AlertaSimplesErro, MensagemMixin } from "../../helpers/MensagemHelper";
import NavigationHelper from "../../helpers/NavigationHelper";
import PacientesConvenio from "../../models/convenio/PacientesConvenio";
import { ConvenioService } from "../../services/ConveioService";
import { PlanoService } from "../../services/PlanoService";
import ModalAtendimentosNaoFinalizados from '../atendimento/ModalAtendimentosNaoFinalizados.vue';
import FormData from "form-data";
import { decode, getToken } from "@/utils/Token";
import PermissoesPadrao from "@/constants/enums/PermissoesPadrao";
import Tenant from "@/models/tenant/Tenant";
import { TenantService } from "@/services/TenantService";

export default Vue.extend({

    props: {
        componente: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            valid: false,
            paciente: new Pacientes(),
            carregando: false,
            pacienteConvenioPrincipal: new PacientesConvenio(),
            pacienteConvenioSecundario: new PacientesConvenio(),
            tenant: new Tenant(),
            tenantService: new TenantService(),
            service: new PacientesService(),
            convenioService: new ConvenioService(),
            planoService: new PlanoService(),
            idPaciente: parseInt(this.$route.params.pacienteId),
            visualizar: this.$route.name == "pacientesFormView" ? true : false,
            desabilitaPlanoPrincipal: true,
            desabilitaPlanoSecundario: true,
            responseOk: false,
            enumSexo: Sexo,
            enumEscolaridade: Escolaridade,
            enumEstadoCivil: EstadoCivil,
            enumRaca: Raca,
            listaConveniosPrincipal: [],
            listaPlanosPrincipal: [],
            listaConveniosSecundario: [],
            listaPlanosSecundario: [],
            masks: MaskHelper,
            idade: '',
        };
    },
    computed: {
        titulo() {
            return this.visualizar
                ? "Visualizar Paciente"
                : this.idPaciente
                    ? "Editar Paciente"
                    : "Novo Paciente";
        },
    },
    mounted: function () {
        this.$nextTick(async function () {
            if (this.idPaciente) {
                this.carregando = true;
                await this.service.Get(this.idPaciente).then(
                    (res) => {
                        this.paciente = res.data;
                        this.carregando = false;
                        this.paciente.dataNascimento = DateHelper.formataParaAnoMesDia(
                            this.paciente.dataNascimento
                        );
                        this.idade = DateHelper.calculaIdadePorExtenso(
                            this.paciente.dataNascimento
                        );
                        if (this.paciente.listaConvenios[0]) {
                            this.paciente.listaConvenios[0].vencimento =
                                DateHelper.formataParaAnoMesDia(
                                    this.paciente.listaConvenios[0].vencimento
                                );
                            if (this.paciente.listaConvenios[0].planoId == 0) {
                                this.paciente.listaConvenios[0].planoId = undefined;
                            }
                        }
                        if (this.paciente.listaConvenios[1]) {
                            this.paciente.listaConvenios[1].vencimento =
                                DateHelper.formataParaAnoMesDia(
                                    this.paciente.listaConvenios[1].vencimento
                                );
                            if (this.paciente.listaConvenios[1].planoId == 0) {
                                this.paciente.listaConvenios[1].planoId = undefined;
                            }
                        }
                    },
                    (err) => {
                        this.carregando = false;
                        AlertaSimplesErro("Aviso:", err);
                    }
                );
                if (this.paciente.listaConvenios.length > 0) {
                    if (this.paciente.listaConvenios[0]) {
                        await this.planoService
                            .getAllSelect(this.paciente.listaConvenios[0].convenioId)
                            .then((res) => {
                                this.listaPlanosPrincipal = res.data;
                                if (this.listaPlanosPrincipal.length > 0) {
                                    this.desabilitaPlanoPrincipal = false;
                                }
                            });

                        this.pacienteConvenioPrincipal = this.paciente.listaConvenios[0];
                    }

                    if (this.paciente.listaConvenios[1]) {
                        await this.planoService
                            .getAllSelect(this.paciente.listaConvenios[1].convenioId)
                            .then((res) => {
                                this.listaPlanosSecundario = res.data;
                                if (this.listaPlanosSecundario.length > 0) {
                                    this.desabilitaPlanoSecundario = false;
                                }
                            });
                        this.pacienteConvenioSecundario = this.paciente.listaConvenios[1];
                    }
                }
                this.carregando = false;
            } else {
                this.paciente = new Pacientes();
                this.pacienteConvenioPrincipal = new PacientesConvenio();
                this.pacienteConvenioSecundario = new PacientesConvenio();
            }
        });
    },
    methods: {
        voltar() {
            NavigationHelper.navigate("pacientes");
        },

        apagarFoto() {
            this.paciente.foto = ''
        },
        async salvar() {
            try {
                const valid = await (this.$refs.observer as any).validate();
                if (!valid) {
                    (this.$refs.form as any).scrollIntoView({ behavior: "smooth" });
                    return;
                }
                if (new Date(this.paciente.dataNascimento) > new Date()) {
                    MensagemMixin(
                        3000,
                        "error",
                        "Não é possível cadastrar um paciente com o nascimento maior que hoje!"
                    );
                    return;
                }
                const listaConvenios = [];
                if (
                    this.pacienteConvenioPrincipal.convenioId !== 0 &&
                    this.pacienteConvenioPrincipal.convenioId !== undefined
                ) {
                    listaConvenios.push(JSON.stringify(this.pacienteConvenioPrincipal));
                }
                if (
                    this.pacienteConvenioSecundario.convenioId !== 0 &&
                    this.pacienteConvenioPrincipal.convenioId !== undefined
                ) {
                    listaConvenios.push(JSON.stringify(this.pacienteConvenioSecundario));
                }
                this.paciente.listaConvenios = "[" + listaConvenios.toString() + "]";
                this.carregando = true;

                const postData = new FormData();

                Object.keys(this.paciente).forEach((key) => {
                    if (
                        typeof (this.paciente as any)[key] !== "undefined" &&
                        (this.paciente as any)[key] !== null
                    ) {
                        if (
                            typeof (this.paciente as any)[key] === "object" &&
                            key !== "file"
                        ) {
                            Object.keys((this.paciente as any)[key]).forEach((subKey) => {
                                if (
                                    (this.paciente as any)[key][subKey] !== null &&
                                    (this.paciente as any)[key][subKey] !== undefined
                                ) {
                                    postData.append(
                                        `${key}.${subKey}`,
                                        (this.paciente as any)[key][subKey]
                                    );
                                }
                            });
                        } else {
                            postData.append(key, (this.paciente as any)[key]);
                        }
                    }
                });
                if (this.idPaciente) {
                    await this.service.Put(this.paciente.id, postData).then(
                        (res) => {
                            MensagemMixin(3000, "success", "Paciente editado com sucesso.");
                            this.responseOk = true;
                        },
                        (err) => {
                            AlertaSimplesErro("Aviso:", err);
                            this.responseOk = false;
                        }
                    );
                } else {
                    await this.service.Post(postData).then(
                        (res) => {
                            MensagemMixin(
                                3000,
                                "success",
                                "Paciente cadastrado com sucesso."
                            );
                            this.responseOk = true;
                        },
                        (err) => {
                            AlertaSimplesErro("Aviso:", err);
                            this.responseOk = false;
                        }
                    );
                }
                if (this.responseOk) {
                    if (this.componente) {
                        this.$emit('pacienteSalvo');
                    }
                    else {
                        this.$router.push({ name: "pacientes", params: { liberar: true } });
                    }
                }
                this.carregando = false;
            } catch (error) {
                this.carregando = false;
            }
        },
        async mudaListaPlanosPrincipal() {
            this.pacienteConvenioPrincipal.planoId = undefined;
            this.preenchePlanosPrincipal();
        },
        async mudaListaPlanosSecundario() {
            this.pacienteConvenioSecundario.planoId = undefined;
            this.preenchePlanosSecundario();
        },
        async preenchePlanosPrincipal() {
            await this.planoService
                .getAllSelect(this.pacienteConvenioPrincipal.convenioId)
                .then((res) => {
                    this.listaPlanosPrincipal = res.data;
                });
            if (
                this.listaPlanosPrincipal.length == 0 ||
                this.pacienteConvenioPrincipal.convenioId == undefined
            ) {
                this.desabilitaPlanoPrincipal = true;
                this.listaPlanosPrincipal = [];
                this.pacienteConvenioPrincipal.numeroRegistro = "";
                this.pacienteConvenioPrincipal.vencimento = "0001-01-01T00:00:00";
            } else {
                this.desabilitaPlanoPrincipal = false;
            }
        },
        async preenchePlanosSecundario() {
            await this.planoService
                .getAllSelect(this.pacienteConvenioSecundario.convenioId)
                .then((res) => {
                    this.listaPlanosSecundario = res.data;
                });
            if (
                this.listaPlanosSecundario.length == 0 ||
                this.pacienteConvenioSecundario.convenioId == undefined
            ) {
                this.desabilitaPlanoSecundario = true;
                this.listaPlanosSecundario = [];
                this.pacienteConvenioSecundario.numeroRegistro = "";
                this.pacienteConvenioSecundario.vencimento = "0001-01-01T00:00:00";
            } else {
                this.desabilitaPlanoSecundario = false;
            }
        },
    },
});
</script>
