import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VNavigationDrawer,{staticClass:"sidebar",attrs:{"app":"","permanent":"","absolute":"","height":"100%","width":_vm.miniSidebar ? '114' : '305',"mini-variant":_vm.miniSidebar,"mini-variant-width":_vm.miniSidebar ? '114' : '305'},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.miniSidebar),expression:"miniSidebar"}],staticClass:"logo-mini"},[_c(VImg,{attrs:{"src":require("../assets/images/favicon-medsystemcloud.png")},on:{"click":_vm.alterarSidebar}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.miniSidebar),expression:"!miniSidebar"}],staticClass:"logo-full"},[_c(VImg,{attrs:{"src":require("../assets/images/logo-sidebar.png")}}),_c(VIcon,{attrs:{"size":"16"},on:{"click":_vm.alterarSidebar}},[_vm._v("$menu")])],1)]),_c(VDivider,{staticClass:"mx-auto sidebar-divider",class:_vm.miniSidebar ? 'sidebar-divider-mini' : ''})],1),_c('div',{ref:"sidebarContent",staticClass:"navbar sidebar-content"},[_c(VList,{staticClass:"navbar-list-full",attrs:{"nav":"","dense":""}},[_vm._l((_vm.items),function(item,index){return [(_vm.temPermissaoNoItem(item))?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.verificaAcesso(item.roles, item.permissions)),expression:"verificaAcesso(item.roles, item.permissions)"}],key:index},[_c(VListItem,{class:[ !_vm.miniSidebar ? 'navbar-item-full' : 'navbar-item-mini', 
                       { 'v-list-item--active': _vm.isActive(item) }],attrs:{"to":item.children.length !== 0 ? '' : item.path,"disabled":_vm.desabilitaModulosComplementares(item.roles, item.permissions)},on:{"click":function($event){$event.stopPropagation();return _vm.alterarSidebar.apply(null, arguments)}}},[_c(VIcon,{staticClass:"navbar-item-icon",attrs:{"size":!_vm.miniSidebar ? '20' : '30'}},[_vm._v(_vm._s(item.icon))]),_c(VListItemTitle,{staticClass:"navbar-item-title"},[_vm._v(" "+_vm._s(item.label)+" ")])],1),_vm._l((item.children),function(child){return _c('div',{key:child.name,class:['navbar-child', { 'mini-mode': _vm.miniSidebar }]},[_c(VListItem,{directives:[{name:"show",rawName:"v-show",value:(_vm.verificaAcesso(child.roles, child.permissions) && !_vm.miniSidebar),expression:"verificaAcesso(child.roles, child.permissions) && !miniSidebar"}],class:[ !_vm.miniSidebar ? 'navbar-child-full' : 'navbar-child-mini', { 'v-list-item--active': _vm.isActive(child) }],attrs:{"to":child.path}},[_c(VIcon,{staticClass:"navbar-child-icon",attrs:{"size":"12"}},[_vm._v(" $arrowRight ")]),(_vm.naoTemPermissaoTriagem(child))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VListItemTitle,_vm._g(_vm._b({class:['navbar-child-title', { disabled: _vm.naoTemPermissaoTriagem(child) }]},'v-list-item-title',attrs,false),on),[_vm._v(" "+_vm._s(child.label)+" ")])]}}],null,true)},[_c('span',[_vm._v(" Entre em contato pelo telefone 0800 707 5900 para"),_c('br'),_vm._v(" adquirir essa funcionalidade ")])]):_c(VListItemTitle,{class:['navbar-child-title', { 'disabled': _vm.naoTemPermissaoTriagem(child) }]},[_vm._v(" "+_vm._s(child.label)+" ")])],1)],1)})],2)]:_vm._e()]})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }