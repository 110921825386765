<template>
  <div class="text-center">
    <v-dialog v-model="modalAberto" width="800">
      <v-card class="mx-auto">
        <v-card-title>
          <v-icon left color="success">mdi-note</v-icon>Nota do dia
        </v-card-title>
        <v-card-text>
          <v-textarea class="mt-4" outlined name="input-7-1" label="Descrição" v-model="notaDia.descricao"
            color="success"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col align="end">
              <v-btn :block="$vuetify.breakpoint.name == 'xs'" :class="{'mb-2' : $vuetify.breakpoint.name == 'xs'}" color="error" @click="excluir()" :disabled="notaDia.descricao === '' || !notaDiaExistente">
                <v-icon left >mdi-minus-circle</v-icon>Excluir
              </v-btn>
              <v-btn :block="$vuetify.breakpoint.name == 'xs'" :class="{'mb-2' : $vuetify.breakpoint.name == 'xs', 'ml-2' : $vuetify.breakpoint.name != 'xs'}" color="error" @click="modalAberto = false">
                <v-icon left>mdi-cancel</v-icon>Cancelar
              </v-btn>
              <v-btn :block="$vuetify.breakpoint.name == 'xs'" :class="{'mb-2' : $vuetify.breakpoint.name == 'xs', 'ml-2' : $vuetify.breakpoint.name != 'xs'}" color="success" @click="salvar()">
                <v-icon left>mdi-checkbox-marked-circle</v-icon> Salvar
              </v-btn>
          </v-col>
        </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import moment from 'moment';
import NotaDia from '../../models/agenda/NotaDia';
import { NotaDiaService } from '../../services/NotaDiaService';
import { AlertaSimples, AlertaSimplesErro } from '../../helpers/MensagemHelper';

export default Vue.component('soi-modalNotaDia', {
  data() {
    return {
      dataCalendario: new Date(Date.now()).toISOString().substr(0, 10),
      modalAberto: false,
      notaDia: new NotaDia,
      notaDiaService: new NotaDiaService,
      idAgenda: 1,
      dataAtual: '',
      notaDiaExistente: false,
    }
  },

  methods: {
    async abrirModalNota(idAgenda: number, Data: string) {
      this.notaDia = new NotaDia();
      this.notaDia.agendaId = idAgenda;
      this.notaDia.data = Data;
      this.idAgenda = idAgenda;
      await this.carregarNotaDiaExistente(idAgenda, Data);
      this.modalAberto = true;
    },

    async carregarNotaDiaExistente(idAgenda: number, data: string) {
      try {
        const response = await this.notaDiaService.GetNotaDoDia(idAgenda, data);
        this.notaDiaExistente = true;
        this.notaDia = response.data;
      } catch (error) {
        if (error.response.status === 404) {
          this.notaDiaExistente = false;
        }
      }
    },

    async salvar() {
      try {
        let response;
        if(this.notaDia.descricao == ''){
          AlertaSimples('Atenção','Não é possível salvar uma nota do dia vazia. Por favor, preencha a nota antes de salvá-la!', 'error');
          return;

        }
        if (this.notaDiaExistente) {
          response = await this.notaDiaService.Put(this.notaDia.id, this.notaDia);    
        } else {
          response = await this.notaDiaService.Post(this.notaDia); 
        }
        this.primeiraVez = false
        this.notaDia = response.data;
        this.notaDia.data = moment(this.notaDia.data).format('YYYY-MM-DD');
        this.modalAberto = false;
        this.$emit('nota-salva', this.notaDia);
      }
      catch (error) {

      }
    },

    async excluir() {
      if (!this.notaDia) {
        throw new Error('Nota não encontrada.');
      }
      await this.notaDiaService.Delete(this.notaDia.id)
      this.modalAberto = false;
      this.notaDia = new NotaDia()
      this.$emit('nota-salva', this.notaDia);
    },
  },
},
)
</script>