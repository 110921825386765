<template>
  <div>
    <v-col cols="12" md="12" sm="12">
      <v-data-table
        :loading="carregando ? 'success' : null"
        no-data-text="Nenhum(a) Configuração Aviso encontrado(a)!"
        loading-text="Carregando os itens desta tabela! Aguarde..."
        :headers="colunasConfiguracao"
        :header-props="headerProps"
        :items="listaConfiguracaoAviso"
        :items-per-page="-1"
        hide-default-footer
        class="elevation-0"
      >
        <template v-slot:top>
          <v-row>
            <v-col class="botao-novo" cols="12" sm="12" md="12">
              <v-btn
                depressed
                color="success"
                class="mb-2"
                @click="abrirModalConfiguracaoAviso()"
              >
                <v-icon>mdi-plus</v-icon>
                Novo
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
              class="mx-2"
              x-small
              fab
              color="success"
              @click="abrirModalAvisoParaEdicao(item)"
              title="Editar Aviso"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              x-small
              fab
              color="error"
              @click="excluirConfiguracaoAviso(item)"
              title="Excluir Aviso"
            >
              <v-icon small>mdi-minus</v-icon>
            </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="modalConfiguracaoAviso"
      max-width="700px"
    >
    <v-card class="mx-auto">
      <v-card-title>
        <v-icon left color="success"> {{ iconeTituloAviso }}</v-icon>{{ tituloAviso }}
      </v-card-title>
      <validation-observer ref="observerAviso" v-slot="{  }">
        <v-form >
          <v-card class="elevation-0">
            <v-card-text class="pa-5">
              <v-row>
                <v-col cols="12" md="6" sm="6">
                  <soi-select
                    :label="'Consulta ou Retorno'"
                    v-model="configuracaoAviso.consultaRetorno"
                    :items="enumTipoConsulta"
                  ></soi-select>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <soi-select
                    :label="'Situação'"
                    v-model="configuracaoAviso.situacaoAgendamento"
                    :items="enumSituacao"
                  ></soi-select>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <soi-select
                    :label="'Aviso'"
                    v-model="configuracaoAviso.tipoAviso"
                    :items="enumAviso"
                  ></soi-select>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <soi-input
                    type="time"
                    :name="'TempoAntesAviso'"
                    v-model="configuracaoAviso.tempoAntesAviso"
                    :label="'Tempo Antes do Aviso'"
                  ></soi-input>
                </v-col>
                <v-col cols="6" md="2" sm="2">
                  <v-container fluid style="display: flex">
                    <v-row>
                      <v-label> Aviso Ativo </v-label>
                      <v-checkbox
                        style="
                          align-items: flex-start;
                          margin-right: 40px;
                        "
                        label="Email"
                        v-model="configuracaoAviso.email"
                        color="success"
                      />
                      <v-checkbox
                        label="SMS"
                        v-model="configuracaoAviso.sms"
                        disabled
                        indeterminate
                      />
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-tabs
                    show-arrows
                    flat
                    color="success"
                    v-model="tabActive"
                  >
                    <v-tab>
                      <v-icon left>mdi-email</v-icon>
                      Configuração Email
                    </v-tab>
                    <v-tab>
                      <v-icon left>mdi-message-processing</v-icon>
                      Configuração SMS
                    </v-tab>
                    <v-tabs-items v-model="tabActive">
                      <form>
                        <v-tab-item>
                          <v-card-text class="mt-3">
                            <v-row>
                              <v-col cols="12" md="12" sm="12">
                                <soi-input
                                  :name="'Assunto'"
                                  :rules="{ required: true }"
                                  v-model="configuracaoAviso.assunto"
                                  :label="'Assunto '"
                                ></soi-input>
                              </v-col>
                              <v-col cols="12" md="12" sm="12">
                                <vue-editor
                                  :rules="{ required: true }"
                                  v-model="configuracaoAviso.mensagem"
                                >
                                </vue-editor>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" md="12" sm="12">
                                <v-alert show color="warning">
                                  <b-icon
                                    icon="exclamation-triangle-fill"
                                    scale="1"
                                    variant="warning"
                                  />
                                  Opção de envio de aviso por SMS
                                  desativada. Entre em contato com a
                                  assistência técnica para ativação
                                </v-alert>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-tab-item>
                      </form>
                    </v-tabs-items>
                  </v-tabs>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" @click="fecharModal()">
                <v-icon left>mdi-keyboard-return</v-icon>
                Voltar
              </v-btn>
              <v-btn
                @click="salvar()"
                color="success"
                :loading="carregando"
              >
                <v-icon left>mdi-checkbox-marked-circle</v-icon>
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import ConfiguracaoAviso from "../../models/agenda/ConfiguracaoAviso";
import {
  SituacaoConfiguracao,
  TipoAviso,
  TipoConsultaRetorno,
} from "../../models/Enums";
import { VueEditor } from "vue2-editor";
import Agenda from "../../models/agenda/Agenda";
import { AgendaService } from "../../services/AgendaService";
import { AlertaExclusaoQuestaoBooleano } from "../../helpers/MensagemHelper";

export default Vue.extend({
  name: "soi-configuracao-aviso",
  components: {
    VueEditor,
  },
  props: {
    value: {
      type: Array as () => ConfiguracaoAviso[],
      required: true,
    },
  },
  watch: {
    value() {
      this.listaConfiguracaoAviso = this.value;
    },
  },
  data() {
    return {
      modalConfiguracaoAviso: false,
      selected: null,
      carregando: false,
      responseOk: false,
      listaConfiguracaoAviso: this.value,
      configuracaoAviso: new ConfiguracaoAviso(),
      enumTipoConsulta: TipoConsultaRetorno,
      enumSituacao: SituacaoConfiguracao,
      enumAviso: TipoAviso,
      AvisoEdicaoIndex: -1,
      agenda: new Agenda(),
      service: new AgendaService(),
      editarConfiguracaoAviso: false,
      tabActive: null,
      headerProps: {
        sortByText: "Ordenar por"
      },
      colunasConfiguracao: [
        {
          value: "assunto",
          align: "center",
          text: "Assunto",
          filterable: true,
          sortable: true,
        },
        {
          value: "tempoAntesAviso",
          align: "center",
          text: "Tempo Antes do Aviso",
          filterable: true,
        },
        {
          value: "actions",
          align: "center",
          text: "Ações",
        },
      ],
    };
  },
  computed: {
    tituloAviso() {
      return this.editarConfiguracaoAviso
        ? "Editar Configuração de Aviso"
        : "Criar Configuração Aviso";
    },
    iconeTituloAviso() {
      return this.editarConfiguracaoAviso ? "mdi-pencil" : "mdi-plus";
    }
  },
  methods: {
    async abrirModalAvisoParaEdicao(aviso: ConfiguracaoAviso) {
      this.AvisoEdicaoIndex = this.listaConfiguracaoAviso.indexOf(aviso);
      this.editarConfiguracaoAviso = true;
      this.modalConfiguracaoAviso = true;
      setTimeout(() => {
        this.configuracaoAviso = Object.assign({}, aviso);
      }, 10);
    },
    async excluirConfiguracaoAviso(aviso: ConfiguracaoAviso) {
      if (await AlertaExclusaoQuestaoBooleano()) {
        const index = this.listaConfiguracaoAviso.indexOf(aviso);
        this.listaConfiguracaoAviso.splice(index, 1);
        this.$emit("input", this.listaConfiguracaoAviso);
      }
    },
    async salvar() {
      const valid = await (this.$refs.observerAviso as any).validate();
      if (!valid) {
        return;
      }
      if (this.editarConfiguracaoAviso) {
        if (this.AvisoEdicaoIndex != -1)
          Object.assign(
            this.listaConfiguracaoAviso[this.AvisoEdicaoIndex],
            this.configuracaoAviso
          );
      } else {
        this.listaConfiguracaoAviso.push(this.configuracaoAviso);
      }

      this.$emit("input", this.listaConfiguracaoAviso);
      this.modalConfiguracaoAviso = false;
      this.editarConfiguracaoAviso = false;
      this.configuracaoAviso = new ConfiguracaoAviso();
      await (this.$refs.observerAviso as any).reset();
    },

    abrirModalConfiguracaoAviso() {
      this.modalConfiguracaoAviso = true;
      this.configuracaoAviso = new ConfiguracaoAviso();
    },

    async fecharModal() {
      this.modalConfiguracaoAviso = false;
      this.editarConfiguracaoAviso = false;
      await (this.$refs.observerAviso as any).reset();
    },
  },
});
</script>
