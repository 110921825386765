<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-data-table
          :loading="carregando ? 'success' : null"
          no-data-text="Nenhum Usuario encontrada(o)!"
          loading-text="Carregando os itens desta tabela! Aguarde..."
          :headers="colunasUsuarios"
          :header-props="headerProps"
          :items="listaUsuario"
          :items-per-page="-1"
          hide-default-footer
          class="elevation-0"
        >
          <template v-slot:top>
            <v-row>
              <v-col class="botao-novo" cols="12" sm="12" md="12">
                <v-btn
                  depressed
                  color="success"
                  class="mb-2"
                  @click="abrirModalUsuario()"
                >
                  <v-icon>mdi-plus</v-icon>
                  Novo
                </v-btn>
              </v-col>
            </v-row>

          </template>
          <template v-slot:item.usuarioId="{ item }">
            {{ item.usuario.nome + " " + item.usuario.sobrenome }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="mx-2"
              x-small
              fab
              color="success"
              @click="abrirModalParaEdicao(item)"
              title="Editar Usuario"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              x-small
              fab
              color="error"
              @click="excluirUsuario(item)"
              title="Excluir Usuario"
            >
              <v-icon small>mdi-minus</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
              transition="dialog-bottom-transition"
              v-model="modalUsuario"
              max-width="700px"
            >
            <v-card class="mx-auto" :loading="carregando ? 'success': null" :disabled="carregando" color="white">
              <v-card-title><v-icon color="success" left>mdi-account-lock-open</v-icon>
                {{ tituloUsuarios }}
              </v-card-title>
              <validation-observer
                ref="observerUsuario"
                v-slot="{ }"
              >
                  <v-card class="elevation-0">
                    <v-card-text class="pa-5">
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-autocomplete
                            :disabled="editar"
                            required
                            v-model="usuarioAgenda.usuarioId"
                            :items="listaUsuarioFiltro"
                            :loading="carregandoBuscarUsuario"
                            :search-input.sync="buscarPeloUsuario"
                            color="success"
                            outlined
                            dense
                            item-text="text"
                            item-value="value"
                            no-data-text="Sem registros"
                            label="Usuário *"
                            placeholder="Digite o nome do usuário"
                          >
                          </v-autocomplete>
                        </v-col>
                          <v-col>
                            <v-list flat
                              subheader
                            >
                              <v-subheader>Permissões</v-subheader>

                              <v-list-item-group
                                multiple
                              >
                                <v-list-item>
                                  <template v-slot:default="{ }">
                                    <v-list-item-action>
                                      <v-checkbox color="success" v-model="usuarioAgenda.permiteCadastroAgendamentos"></v-checkbox>
                                    </v-list-item-action>

                                    <v-list-item-content>
                                      <v-list-item-title>Cadastrar Agendamentos</v-list-item-title>
                                      <v-list-item-subtitle>Criar, editar e excluir agendamentos.</v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>

                                <v-list-item>
                                  <template v-slot:default="{ }">
                                    <v-list-item-action>
                                      <v-checkbox color="success" v-model="usuarioAgenda.permiteCadastroHorarios"></v-checkbox>
                                    </v-list-item-action>

                                    <v-list-item-content>
                                      <v-list-item-title>Configurar horários</v-list-item-title>
                                      <v-list-item-subtitle>Criar, editar e excluir horários de atendimento na agenda.</v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>

                                <v-list-item>
                                  <template v-slot:default="{ }">
                                    <v-list-item-action>
                                      <v-checkbox color="success" v-model="usuarioAgenda.permiteCadastroBloqueios"></v-checkbox>
                                    </v-list-item-action>

                                    <v-list-item-content>
                                      <v-list-item-title>Configurar bloqueios</v-list-item-title>
                                      <v-list-item-subtitle>Criar, editar e excluir bloqueios de datas na agenda.</v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>

                                <v-list-item>
                                  <template v-slot:default="{ }">
                                    <v-list-item-action>
                                      <v-checkbox color="success" v-model="usuarioAgenda.permiteCadastroFeriados"></v-checkbox>
                                    </v-list-item-action>

                                    <v-list-item-content>
                                      <v-list-item-title>Configurar feriados</v-list-item-title>
                                      <v-list-item-subtitle>Criar, editar e excluir feriados de datas na agenda.</v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>

                                <v-list-item>
                                  <template v-slot:default="{ }">
                                    <v-list-item-action>
                                      <v-checkbox color="success" v-model="usuarioAgenda.permiteCadastroTipoConsulta"></v-checkbox>
                                    </v-list-item-action>

                                    <v-list-item-content>
                                      <v-list-item-title>Configurar tipos de consulta</v-list-item-title>
                                      <v-list-item-subtitle>Criar, editar e excluir tipos de consulta da agenda.</v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>

                                <v-list-item>
                                  <template v-slot:default="{ }">
                                    <v-list-item-action>
                                      <v-checkbox color="success" v-model="usuarioAgenda.permiteCadastroAvisos"></v-checkbox>
                                    </v-list-item-action>

                                    <v-list-item-content>
                                      <v-list-item-title>Configurar avisos</v-list-item-title>
                                      <v-list-item-subtitle>Criar, editar e excluir avisos da agenda.</v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>



                              </v-list-item-group>
                            </v-list>
                          </v-col>


                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="warning" @click="fecharModal()">
                        <v-icon left>mdi-keyboard-return</v-icon>
                        Voltar
                      </v-btn>
                      <v-btn
                        @click="salvar()"
                        color="success"
                        :loading="carregando" :disabled="carregando"
                      >
                        <v-icon left>mdi-checkbox-marked-circle</v-icon>
                        Salvar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
              </validation-observer>
            </v-card>
            </v-dialog>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { AlertaExclusaoQuestaoBooleano } from "../../helpers/MensagemHelper";
import AgendaUsuario from "../../models/agenda/AgendaUsuario";
import { UsuarioService } from "../../services/UsuarioService";

export default Vue.extend({
  name: "soi-usuarioAcesso",
  props: {
    value: {
      type: Array as () => AgendaUsuario[],
      required: true,
    },
  },
  watch: {
    value() {
      this.listaUsuario = this.value;
    },
    buscarPeloUsuario(val: string) {
      if ((val && val.length <= 1) || !val) return;
      this.carregandoBuscarUsuario = true;
      this.usuarioService.GetAllPaginado(val, 5, 1).then(
        (res) => {
          this.listaUsuarioFiltro = [];
          res.data.itens.forEach((usuario: { id: any; nomeCompleto: any }) => {
            this.listaUsuarioFiltro.push({
              value: usuario.id,
              text: usuario.nomeCompleto,
            });
          });
        },
        (err) => {
          this.listaUsuarioFiltro = [];
          this.carregandoBuscarUsuario = false;
        }
      );

      this.carregandoBuscarUsuario = false;
    },
  },
  computed: {
    tituloUsuarios() {
      return this.editar
        ? "Editar Permissões de Usuário desta agenda"
        : "Criar Permissões de Usuário desta agenda";
    },
  },
  data() {
    return {
      carregando: false,
      usuarioAgenda: new AgendaUsuario(),
      listaUsuario: this.value,
      listaUsuarioFiltro: new Array<{}>(),
      carregandoBuscarUsuario: false,
      buscarPeloUsuario: "",
      modalUsuario: false,
      usuarioService: new UsuarioService(),
      editar: false,
      agendaUsuarioEdicaoIndex: 0,
      headerProps: {
        sortByText: "Ordenar por",
      },
      colunasUsuarios: [
        {
          value: "usuarioId",
          align: "center",
          text: "Usuários que podem utilizar esta agenda",
          filterable: true,
          sortable: true,
        },
        {
          text: "Ações",
          align: "center",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    async salvar(): Promise<void> {
      const valid = await (
        this.$refs.observerUsuario as { validate: () => Promise<boolean> }
      ).validate();
      if (!valid) return;
      if (this.editar){
        Object.assign(this.listaUsuario[this.agendaUsuarioEdicaoIndex],this.usuarioAgenda);
      }
      else {
        this.usuarioAgenda.usuario.nome = this.listaUsuarioFiltro.find(x => x.value == this.usuarioAgenda.usuarioId).text;
        this.listaUsuario.push(this.usuarioAgenda);
      }

      this.$emit("input", this.listaUsuario);
      this.editar = false;
      this.modalUsuario = false;
      this.usuarioAgenda = new AgendaUsuario();
    },
    abrirModalUsuario() {
      this.modalUsuario = true;
      this.usuarioAgenda = new AgendaUsuario();
    },
    abrirModalParaEdicao(agendaUsuario: AgendaUsuario) {
      this.agendaUsuarioEdicaoIndex = this.listaUsuario.indexOf(agendaUsuario);
      this.buscarPeloUsuario = agendaUsuario.usuario.nome + ' ' + agendaUsuario.usuario.sobrenome;
      this.editar = true;
      this.modalUsuario = true;
      this.usuarioAgenda = agendaUsuario;
    },
    async fecharModal() {
      this.editar = false;
      this.modalUsuario = false;
      this.usuarioAgenda = new AgendaUsuario();
    },
    async excluirUsuario(usuario: AgendaUsuario) {
      if (await AlertaExclusaoQuestaoBooleano()) {
        const index = this.listaUsuario.indexOf(usuario);
        this.listaUsuario.splice(index, 1);
        this.$emit("input", this.listaUsuario);
      }
    },
  },
});
</script>
