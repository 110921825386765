import Role from "@/constants/enums/Role";
import Permissao from '@/constants/enums/PermissoesPadrao';


export interface IMenuItem {
    name: string;
    params?: any;
    path: string;
    label: string;
    icon?: string;
    children: Array<IMenuItem>;
    relatedRoutes?: Array<string>;
    roles: Array<string>;
    permissions: Array<string>;
}

const items: Array<IMenuItem> = [
    {
        name: 'home',
        path: '/home',
        label: 'Home',
        icon: '$home',
        roles: [
            Role.Admin,
            Role.Host,
            Role.Medico,
            Role.Secretario,
            Role.TecEnfermagem,
            Role.Enfermeiro,
            Role.Administrador

        ],
        permissions: [],
        children: []
    },
    {
        name: 'clinicas',
        path: '/clinicas',
        label: 'Clínicas',
        icon: 'mdi-hospital-box-outline',
        relatedRoutes: [
            'clinicasForm',
            'clinicasFormUpdate',
            'clinicasFormView',
        ],
        roles: [
            Role.Host,
        ],
        permissions: [Permissao.TenantRead],
        children: []
    },
    {
        name: 'Agenda',
        path: '/agenda',
        label: 'Agendas',
        icon: '$calendar',
        roles: [
            Role.Admin,
            Role.Medico,
            Role.Secretario,
            Role.TecEnfermagem,
            Role.Enfermeiro,
            Role.Administrador
        ],
        permissions: [Permissao.AgendaRead],
        children: []
    },
    {
        name: 'atendimentos',
        path: '/',
        label: 'Atendimento',
        icon: '$briefcase',
        relatedRoutes: [
            'atendimentoForm',
            'atendimentoFormUpdate',
        ],
        roles: [
            Role.Medico,
            Role.Secretario,
            Role.Enfermeiro,

        ],
        permissions: [],
        children: [{
            name: 'atendimento',
            path: '/atendimentos',
            label: 'Atendimento',
            relatedRoutes: [
                'atendimentoForm',
                'atendimentoFormUpdate',
            ],
            roles: [
                Role.Medico,
                Role.Secretario,
                Role.Enfermeiro,

            ],
            permissions: [Permissao.PacienteRead],
            children: []
        }],
    },
    {
        name: 'Cadastros',
        path: '/cadastros',
        label: 'Cadastros',
        icon: '$documentAdd',
        roles: [
            Role.Admin,
            Role.Medico,
            Role.Secretario,
            Role.TecEnfermagem,
            Role.Enfermeiro,
            Role.Administrador
        ],
        permissions: [],
        children: [
            {
                name: 'pacientes',
                path: '/pacientes',
                label: 'Pacientes',
                relatedRoutes: [
                    'pacientesForm',
                    'pacientesFormUpdate',
                    'pacientesFormView',
                ],
                roles: [
                    Role.Admin,
                    Role.Medico,
                    Role.Secretario,
                    Role.TecEnfermagem,
                    Role.Enfermeiro,
                    Role.Administrador

                ],
                permissions: [Permissao.PacienteRead],
                children: []
            },
            {
                name: 'convenios',
                path: '/convenios',
                label: 'Convênios',
                relatedRoutes: [
                    'conveniosForm',
                    'conveniosFormUpdate',
                    'conveniosFormView',
                ],
                roles: [
                    Role.Admin,
                    Role.Medico,
                    Role.Secretario,
                    Role.TecEnfermagem,
                    Role.Enfermeiro,
                    Role.Administrador

                ],
                permissions: [Permissao.ConvenioRead],
                children: [],
            },

        ],
    },
    {
        name: 'relatorios',
        path: '/relatorios',
        label: 'Relatórios',
        icon: '$reportBox',
        roles: [
            Role.Admin,
            Role.Medico,
            Role.Secretario,
            Role.TecEnfermagem,
            Role.Enfermeiro,
            Role.Administrador
        ],
        permissions: [],
        children: [
            {
                name: 'logsAuditoria',
                path: '/logs-auditoria',
                label: 'Auditoria',
                roles: [
                    Role.Host,
                    Role.Admin,
                    Role.Medico,
                ],
                relatedRoutes: [
                    'logsAuditoria'
                ],
                permissions: [Permissao.AuditoriaRead],
                children: []
            }
        ]
    },
    {
        name: 'triagens',
        path: '/',
        label: 'Triagem',
        icon: '$clinical',
        roles: [
            Role.Admin,
            Role.Medico,
            Role.Secretario,
            Role.TecEnfermagem,
            Role.Enfermeiro,
            Role.Administrador
        ],
        permissions: [],
        children: [
            {
                name: 'triagens',
                path: '/triagens',
                label: 'Triagem',
                relatedRoutes: [
                    'triagens',
                ],
                roles: [
                    Role.Admin,
                    Role.Medico,
                    Role.Secretario,
                    Role.Enfermeiro,
                    Role.Administrador
                ],
                permissions: [Permissao.TriagemReadAll],
                children: []
            },
        ]
    },
    {
        name: 'configuracoes',
        path: '/configuracoes',
        label: 'Configurações',
        icon: '$displaySettings',
        roles: [
            Role.Admin,
            Role.Medico,
            Role.Secretario,
            Role.TecEnfermagem,
            Role.Enfermeiro,
            Role.Administrador
        ],
        permissions: [],
        children: [
            {
                name: 'usuarios',
                path: '/usuarios',
                label: 'Usuarios',
                relatedRoutes: [
                    'usuario',
                    'visualizarUsuario'
                ],
                roles: [
                    Role.Admin,
                    Role.Medico,
                ],
                permissions: [Permissao.UsuarioRead],
                children: []
            },
            {
                name: 'impressosPersonalizados',
                path: '/impressos-personalizados',
                label: 'Impressos',
                roles: [
                    Role.Host,
                    Role.Admin,
                    Role.Medico,
                ],
                relatedRoutes: [
                    'impressosPersonalizados'
                ],
                permissions: [Permissao.ImpressoPersonalizadoRead],
                children: []
            },
            {
                name: 'consultasPersonalizadas',
                path: '/consultas-personalizadas',
                label: 'Consultas',
                relatedRoutes: [
                    'consultaPersonalizadaForm'
                ],
                roles: [
                    Role.Admin,
                    Role.Medico,
                    Role.Secretario,
                    Role.Enfermeiro,
                    Role.Administrador

                ],
                permissions: [],
                children: []
            },
            {
                name: 'quadrosCustomizados',
                path: '/quadros-customizados',
                label: 'Quadros',
                relatedRoutes: [
                    'quadrosCustomizadosForm'
                ],
                roles: [
                    Role.Admin,
                    Role.Medico,
                    Role.Secretario,
                    Role.Enfermeiro,
                    Role.Administrador

                ],
                permissions: [],
                children: []
            },
        ]
    }
]

export default items;