<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="2 6 15 15" fill="none">
		<path d="M11.421 12.6317L13.1128 14.6054L11.421 16.5791" stroke="#10A860" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M14.4662 12.6317L16.1579 14.6054L14.4662 16.5791" stroke="#10A860" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.component('soi-icon-arrow-right', {
    
})
</script>
<style>

</style>