<template>
    <v-dialog v-model="modalPesquisarModelo" width="1200" height="600">
        <v-card class="mx-auto">
            <v-toolbar flat dark color="success">
                <v-toolbar-title>
                    <v-icon>mdi mdi-file-search-outline</v-icon>
                    Pesquisar Modelos
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="fecharModal()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-card>
                    <v-card-text>
                        <v-data-table
                            :headers="colunas"
                            no-data-text="Nenhum modelo encontrado!"
                            :loading="carregando ? 'success' : null"
                            loading-text="Carregando os itens desta tabela! Aguarde..."
                            :items-per-page="itens"
                            :items="modelos"
                            :page.sync="paginaAtual"
                            :search="valorFiltro"
                            :header-props="headerProps"
                            hide-default-footer
                            item-key="id"
                            :single-expand="true"
                            :expanded.sync="expanded"
                            show-expand
                        >
                            <template v-slot:top>
                                <v-card class="elevation-0">
                                    <v-card-text>
                                        <v-row cols="12">
                                            <v-col sm="4" md="4">
                                                <v-radio-group
                                                    v-model="tipoFiltro"
                                                    row
                                                >
                                                    <v-radio
                                                        color="success"
                                                        class="radioFlex"
                                                        v-for="itens in opcoesFiltro"
                                                        :label="itens.name"
                                                        :value="itens.key"
                                                        :key="itens.key"
                                                        @change="limpaPesquisa"
                                                    >
                                                    </v-radio>
                                                </v-radio-group>
                                            </v-col>
                                            <v-col sm="6" md="6">
                                                <v-text-field
                                                    :type="tipoFiltroComputado"
                                                    outlined
                                                    dense
                                                    color="success"
                                                    v-model="valorFiltro"
                                                    @input="listar"
                                                    append-icon="mdi-magnify"
                                                    :label="labelFiltro"
                                                    class="mt-4"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col sm="2" md="2">
                                                <v-select
                                                    outlined
                                                    dense
                                                    color="success"
                                                    v-model="itens"
                                                    label="Qtde.Itens"
                                                    :items="quantidadeItens"
                                                    @input="listar"
                                                    class="mt-4"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </template>
                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                    {{ item.descricao }}
                                </td>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-btn
                                    class="mx-2"
                                    x-small
                                    fab
                                    color="primary"
                                    @click="selecionarModelo(item)"
                                    title="Selecionar Modelo"
                                >
                                    <v-icon small>mdi-check-bold</v-icon>
                                </v-btn>
                                <v-btn
                                    class="mx-2"
                                    x-small
                                    fab
                                    color="error"
                                    @click="excluir(item.id)"
                                    title="Excluir Modelo"
                                >
                                    <v-icon small>mdi-minus</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                        <v-pagination
                            color="success"
                            @input="listar"
                            v-model="paginaAtual"
                            :length="totalPaginas"
                            :total-visible="5"
                        ></v-pagination>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions class="justify-end">
                <v-btn color="error" @click="fecharModal">
                    <v-icon>mdi-window-close</v-icon>Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import {
    AlertaExclusaoQuestaoBooleano,
    AlertaSimplesErro,
    MensagemMixin,
} from '../../helpers/MensagemHelper';
import { ModelosService } from '../../services/ModelosService';

export default {
    name: 'soi-pesquisar-modelo',
    props: {
        tipoModeloAtivo: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            carregando: false,
            modalPesquisarModelo: false,
            modelos: [],
            expanded: [],
            tipoFiltro: 'nomeId',
            tipoFiltroNumber: 0,
            valorFiltro: '',
            headerProps: {
                sortByText: 'Ordenar por',
            },
            quantidadeItens: [
                { value: 5, text: 5 },
                { value: 10, text: 10 },
                { value: 15, text: 15 },
            ],
            opcoesFiltro: [
                { name: 'Título', key: 'nomeId', type: 'string' },
                { name: 'Nome do Proprietário', key: 'usuario', type: 'string' },
            ],
            totalPaginas: 0,
            paginaAtual: 1,
            itens: 5,
            colunas: [
                {
                    text: 'Título',
                    value: 'nome',
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Tipo',
                    value: 'tipoModelo',
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Proprietário',
                    value: 'usuario.nome',
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Ações',
                    value: 'actions',
                },
            ],
            service: new ModelosService(),
        };
    },
    computed: {
        tipoFiltroComputado() {
            return this.opcoesFiltro.find((x) => x.key == this.tipoFiltro)
                ?.type;
        },
        labelFiltro() {
            const itemSelecionado = this.opcoesFiltro.find(item => item.key === this.tipoFiltro);

            if (itemSelecionado) {
                return `Digite um ${itemSelecionado.name} para pesquisar...`;
            }

            return 'Digite o que deseja pesquisar...';
        }
    },
    watch: {
        value() {
            this.modeloSelecionado = this.value;
        },
    },
    methods: {
        abrirModalPesquisarModelo() {
            this.itens = 5;
            this.modalPesquisarModelo = true;
            this.listar();
            this.carregando = true;
        },

        fecharModal() {
            this.modalPesquisarModelo = false;
            this.limpaPesquisa();
        },

        limpaPesquisa() {
            this.paginaAtual = 1;
            this.valorFiltro = '';
            this.listar();
        },

        pesquisa() {
            this.paginaAtual = 1;
            this.listar();
        },

        selecionarModelo(modelo) {
            this.$emit('selecionar-modelo', modelo);
            this.modalPesquisarModelo = false;
        },

        async listar() {
            this.carregando = true;
            await this.service
                .GetAllPaginado(
                    this.tipoFiltro == 'nomeId' ? this.valorFiltro : '',
                    this.tipoFiltro == 'usuario' ? this.valorFiltro : '',
                    this.tipoModeloAtivo,
                    this.itens,
                    this.paginaAtual
                )
                .then(
                    (res) => {
                        this.modelos = res.data.itens;
                        this.totalPaginas = res.data.totalPaginas;
                        this.paginaAtual = res.data.paginaAtual;
                    },
                    (err) => {
                        AlertaSimplesErro('Aviso:', err);
                    }
                );
            this.carregando = false;
        },

        async excluir(modeloId: number) {
            if (await AlertaExclusaoQuestaoBooleano()) {
                this.service.Delete(modeloId).then(
                    (res) => {
                        this.listar();
                        MensagemMixin(
                            3000,
                            'success',
                            'Modelo deletado com sucesso.'
                        );
                    },
                    (err) => {
                        AlertaSimplesErro('Aviso:', err);
                    }
                );
            }
        },
    },
};
</script>
