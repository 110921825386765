<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
		<path fill="currentColor" d="m2.75 7l1.28-1.25L13.26 15L20 21.72L18.73 23l-3-3H4v-3c0-1.86 2.61-3.08 5.09-3.64zM20 17v2.18l-1.9-1.9V17c0-.26-.5-.65-1.3-1L14 13.18c2.71.45 6 1.73 6 3.82M5.9 17v1.1h7.93L10.72 15c-2.53.3-4.82 1.45-4.82 2M12 4a4 4 0 0 1 4 4c0 1.95-1.4 3.58-3.25 3.93L8.07 7.25A4.004 4.004 0 0 1 12 4m0 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2" />
	</svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.component('soi-icon-account-off', {
    
})
</script>