import { AlertaPerguntaSimOuNaoBooleano, AlertaSimples } from '@/helpers/MensagemHelper'
import { getToken, getUserPermissions } from '@/utils/Token'
import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import Navigation from '../helpers/NavigationHelper'
import PermissoesPadrao from '@/constants/enums/PermissoesPadrao'

Vue.use(VueRouter)

const routes = [
    {
        path: "*",
        redirect: "/home"
    },
    {
        path: "/",
        redirect: "/login"
    },
    {
        path:"/",
        redirect:"/recuperar-senha"
    },
    {
        path: '/confirma-email',
        name: 'confirmaEmail',
        component: () => import('../views/emails/ConfirmaEmail.vue'),
    },
    {
        path: '/recuperar-senha',
        name: 'recuperarSenha',
        component: () => import('../views/emails/RecuperarSenha.vue'),
    },
    {
        path: '/confirma-agendamento/:token',
        name: 'confirmaAgendamento',
        component: () => import('../views/confirmaAgendamento/ConfirmaAgendamento.vue'),
    },
    {
        path: '/loginPaciente',
        name: 'loginPaciente',
        component: () => ('../views/paciente/loginPaciente.vue'),
    },
    {
        path: '/cadastroPaciente',
        name: 'cadastroPaciente',
        component: () => ('../views/paciente/cadastroPaciente.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/Login.vue')
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/Home.vue'),
        meta: { auth: true },
    },
    {
        path: '/pacientes',
        name: 'pacientes',
        component: () => import('../views/paciente/ListaPacientes.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.PacienteReadAll
            ]
        },
    },
    {
        path: '/paciente/:pacienteId?',
        name: 'pacientesForm',
        component: () => import('../views/paciente/formPacientes.vue'),
        meta: {
            auth: true,
            permissions: [                
                PermissoesPadrao.PacienteCreate,
                PermissoesPadrao.PacienteDelete
            ]
        },
    },
    {
        path: '/paciente/:pacienteId?',
        name: 'pacientesFormUpdate',
        component: () => import('../views/paciente/formPacientes.vue'),
        meta: {
            auth: true,
            permissions: [                
                PermissoesPadrao.PacienteUpdate                
            ]
        },
    },
    {
        path: '/paciente/visualizar/:pacienteId',
        name: 'pacientesFormView',
        component: () => import('../views/paciente/formPacientes.vue'),
        meta: {
            auth: true,
            permissions: [                
                PermissoesPadrao.PacienteRead             
            ]
        },
    },
    {
        path: '/convenios',
        name: 'convenios',
        component: () => import('../views/convenio/ListaConvenio.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.ConvenioReadAll
            ]
        },
    },
    {
        path: '/convenio/:id?',
        name: 'conveniosForm',
        component: () => import('../views/convenio/formConvenio.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.ConvenioCreate,
                PermissoesPadrao.ConvenioUpdate
            ]
        },
    },
    {
        path: '/convenio/:id?',
        name: 'conveniosFormUpdate',
        component: () => import('../views/convenio/formConvenio.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.ConvenioUpdate  
            ]
        },
    },
    {
        path: '/convenio/visualizar/:id',
        name: 'conveniosFormView',
        component: () => import('../views/convenio/formConvenio.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.ConvenioRead
            ]
        },
    },
    {
        path: '/agenda',
        name: 'Agenda',
        component: () => import('../views/agenda/Agenda.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.AgendaCreate,
                PermissoesPadrao.AgendaDelete,
                PermissoesPadrao.AgendaRead,
                PermissoesPadrao.AgendaReadAll,
                PermissoesPadrao.AgendaUpdate,
                PermissoesPadrao.AgendamentoCreate,
                PermissoesPadrao.AgendamentoDelete,
                PermissoesPadrao.AgendamentoRead,
                PermissoesPadrao.AgendamentoUpdate
            ]
        },
    },
    {
        path: '/clinicas',
        name: 'clinicas',
        component: () => import('../views/multiTenancy/listaClinica.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.TenantReadAll,
                PermissoesPadrao.TenantRead,                
                PermissoesPadrao.ClinicaRead,                                
            ]
        },
    },
    {
        path: '/clinica/:id?',
        name: 'clinicasForm',
        component: () => import('../views/multiTenancy/formClinica.vue'),
        meta: {
            auth: true,
            permissions: [                
                PermissoesPadrao.TenantCreate,
                PermissoesPadrao.TenantUpdate,
                PermissoesPadrao.TenantDelete,
                PermissoesPadrao.ClinicaRead,
                PermissoesPadrao.ClinicaUpdate            
            ]
        },
    },
    {
        path: '/clinica/:id?',
        name: 'clinicasFormUpdate',
        component: () => import('../views/multiTenancy/formClinica.vue'),
        meta: {
            auth: true,
            permissions: [                
                PermissoesPadrao.TenantUpdate,                
                PermissoesPadrao.ClinicaUpdate,
                PermissoesPadrao.TenantPatch
            ]
        },
    },
    {
        path: '/clinica/visualizar/:id',
        name: 'clinicasFormView',
        component: () => import('../views/multiTenancy/formClinica.vue'),
        meta: {
            auth: true,
            permissions: [                
                PermissoesPadrao.TenantRead,                
                PermissoesPadrao.ClinicaRead,                                      
            ]
        },
    },
    {
        path: '/atendimentos',
        name: 'atendimentos',
        component: () => import('../views/atendimento/ListaAtendimentos.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.AtendimentoReadAll                
            ]
        },
    },
    {
        path: '/atendimento/:pacienteId/:agendamentoId',
        name: 'atendimentoForm',
        component: () => import('../views/atendimento/Atendimento.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.AtendimentoCreate,
                PermissoesPadrao.AtendimentoUpdate,                
            ]
        },
    },
    {
        path: '/atendimento/editar/:pacienteId/:idAtendimento',
        name: 'atendimentoFormUpdate',
        component: () => import('../views/atendimento/Atendimento.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.AtendimentoUpdate, 
            ]
        },
    },
    {
        path: '/usuarios',
        name: 'usuarios',
        component: () => import('../views/usuario/ListaUsuarios.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.UsuarioReadAll                
            ]
        },
    },
    {
        path: '/usuario/:id?',
        name: 'usuario',
        component: () => import('../views/usuario/FormUsuario.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.UsuarioCreate,
                PermissoesPadrao.UsuarioUpdate,
                PermissoesPadrao.UsuarioDelete,
            ]
        },
    },
    {
        path: '/usuario/visualizar/:id',
        name: 'visualizarUsuario',
        component: () => import('../views/usuario/FormUsuario.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.UsuarioRead               
            ]
        },
    },
    {
        path: '/usuario/:id',
        name: 'editarUsuario',
        component: () => import('../views/usuario/FormUsuario.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.UsuarioUpdate
            ]
        },
    },
    {
        path: '/seu-perfil',
        name: 'perfilDoUsuario',
        component: () => import('../views/usuario/FormUsuario.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.ProfileUpdate
            ]
        },
    },
    {
        path: '/consultas-personalizadas',
        name: 'consultasPersonalizadas',
        component: () => import('../views/consultasPersonalizadas/ListaConsultasPersonalizadas.vue'),
        meta: { auth: true },
    },
    {
        path: '/quadros-customizados',
        name: 'quadrosCustomizados',
        component: () => import('../views/quadrosCustomizados/ListaQuadrosCustomizados.vue'),
        meta: { auth: true },
    },
    {
        path: '/triagens',
        name: 'triagens',
        component: () => import('../views/triagem/ListaTriagem.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.AcessoTriagem,
                PermissoesPadrao.TriagemReadAll
            ]
        },
    },
    {
        path: '/triagens/:pacienteId/:agendamentoId',
        name: 'triagensForm',
        component: () => import('../views/triagem/FormTriagem.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.AcessoTriagem,  
                PermissoesPadrao.TriagemRead,
                PermissoesPadrao.TriagemCreate               
            ]
        },
    },   
    {
        path: '/logs-auditoria',
        name: 'logsAuditoria',
        component: () => import('../views/auditoria/logsAuditoria.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.AuditoriaReadAll,                                   
            ]
        },
    },
    {
        path: '/impressos',
        name: 'impressos',
        component: () => import('../views/impressos/ImpressosDesigner.vue'),
        meta: { auth: true },
    },
    {
        path: '/visualiza-impresso/:nomeReport',
        name: 'visualiza-impresso',
        component: () => import('../views/impressos/ImpressosViewer.vue'),
        meta: { auth: true },
    },
    {
        path: '/impressos-personalizados',
        name: 'impressosPersonalizados',
        component: () => import('../views/impressosPersonalizados/ListaImpressosPersonalizados.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.ImpressoPersonalizadoReadAll,
            ]
        },
    },
    {
        path: '/impresso-personalizado/:id?',
        name: 'impressosPersonalizadosForm',
        component: () => import('../views/impressosPersonalizados/FormImpressoPersonalizado.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.ImpressoPersonalizadoCreate,
                PermissoesPadrao.ImpressoPersonalizadoUpdate,                
            ]
        },
    },
    {
        path: '/impresso-personalizado/visualizar/:impressoPersonalizadoId',
        name: 'impressoPersonalizadoFormView',
        component: () => import('../views/impressosPersonalizados/FormImpressoPersonalizado.vue'),
        meta: {
            auth: true,
            permissions: [
                PermissoesPadrao.ImpressoPersonalizadoRead
            ]
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    const token: string | null = getToken(); 
    
    if ((to.name !== 'login' && to.name !== 'recuperarSenha' && to.name !== 'confirmaEmail' && to.name !== 'confirmaAgendamento') && !token) {
        // next('/login' && '/recuperar-senha' && '/confirma-email'); 
        next('/login'); 
    } else if (to.meta && to.meta.permissions) {
        const userPermissions: string[] = getUserPermissions(token);
        const requiredPermissions: string[] = to.meta.permissions as string[];  

        var hasAccess: boolean;

        if (requiredPermissions.includes(PermissoesPadrao.AcessoTriagem)) {
            hasAccess = requiredPermissions.every(item => userPermissions.includes(item));          
        } else {
            hasAccess = userPermissions.some(permission => requiredPermissions.includes(permission)); 
        }               
        
        if (hasAccess) {
            next();
        } else {
            AlertaSimples('Acesso não autorizado', 'Você não possui permissão para acessar esta página', 'warning');
            next('/home')
        }
    } else {
        next();
    }
});


const rotasParaForm = [
    'pacientesForm',
    'conveniosForm',
    'clinicasForm',
    'atendimentoForm',
    'usuario',
];

const rotasParaFormEditar = [
    'pacientesFormUpdate',
    'clinicasFormUpdate',
    'conveniosFormUpdate',
    'atendimentoFormUpdate',
];

const naoPermitirParaRotas = [
    'home',
    'login',
    'pacientes',
    'convenios',
    'Agenda',
    'clinicas',
    'usuarios',
    'atendimentos',
    'consultasPersonalizadas',
    'quadrosCustomizados',
    'triagem'
];


let confirmNavigation = true;

const storedNavigation = sessionStorage.getItem('confirmNavigation');
if (storedNavigation !== null) {
    confirmNavigation = storedNavigation === 'true';
}

window.addEventListener('beforeunload', function (e) {
    if (confirmNavigation && !naoPermitirParaRotas.includes(router.currentRoute.name)) {
        e.preventDefault();
        e.returnValue = '';
    }
});

router.beforeResolve(async (to, from, next) => {
    if (rotasParaForm.includes(from.name)) {
        if (!to.params.liberar) {
            if (confirmNavigation && !(await AlertaPerguntaSimOuNaoBooleano('É possível que as alterações feitas não sejam salvas.\r\n Deseja sair?'))) {
                return false;
            }
            confirmNavigation = false;
            sessionStorage.setItem('confirmNavigation', String(confirmNavigation));
            next();
        } else {
            confirmNavigation = false;
            sessionStorage.setItem('confirmNavigation', String(confirmNavigation));
            next();
        }
    } else {
        confirmNavigation = true;
        sessionStorage.setItem('confirmNavigation', String(confirmNavigation));
        next();
    }
});


router.beforeEach(async (to, from, next) => {
    if (rotasParaFormEditar.includes(from.name)) {
        if (!to.params.liberar) {
            if (confirmNavigation && !(await AlertaPerguntaSimOuNaoBooleano('Tem certeza que deseja sair?'))) {
                return false;
            }
            confirmNavigation = false;
            sessionStorage.setItem('confirmNavigation', String(confirmNavigation));
            next();
        } else {
            confirmNavigation = false;
            sessionStorage.setItem('confirmNavigation', String(confirmNavigation));
            next();
        }
    } else {
        confirmNavigation = true;
        sessionStorage.setItem('confirmNavigation', String(confirmNavigation));
        next();
    }
});


export default router
