import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"800","height":"600"},model:{value:(_vm.modalAdicionarModelo),callback:function ($$v) {_vm.modalAdicionarModelo=$$v},expression:"modalAdicionarModelo"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VToolbar,{attrs:{"flat":"","dark":"","color":"success"}},[_c(VToolbarTitle,[_c(VIcon,[_vm._v("mdi mdi-content-save-check")]),_vm._v(" Adicionar Modelo ")],1),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":_vm.fecharModal}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"mt-4"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","xs":"12","md":"4"}},[_c(VTextField,{attrs:{"label":"Tipo do modelo","outlined":"","dense":"","disabled":true},model:{value:(_vm.modelo.tipoModelo),callback:function ($$v) {_vm.$set(_vm.modelo, "tipoModelo", $$v)},expression:"modelo.tipoModelo"}})],1),_c(VCol,{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('soi-input',{attrs:{"append-icon":"mdi-magnify","label":"Titulo do modelo"},model:{value:(_vm.modelo.nome),callback:function ($$v) {_vm.$set(_vm.modelo, "nome", $$v)},expression:"modelo.nome"}})],1)],1)],1),_c(VDivider),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"color":"error"},on:{"click":_vm.fecharModal}},[_c(VIcon,[_vm._v("mdi-cancel")]),_vm._v("Cancelar")],1),_c(VBtn,{staticClass:"mr-2",attrs:{"color":"success","disabled":this.modelo.nome.length === 0 || this.carregando,"loading":_vm.carregando},on:{"click":_vm.salvar}},[_c(VIcon,[_vm._v("mdi mdi-content-save-check")]),_vm._v(" Salvar ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }