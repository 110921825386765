import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-center"},[_c(VCard,{staticClass:"mt-2 mb-2",attrs:{"flat":""}},[_c(VCardTitle,[_vm._v("Convênio")]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","xs":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6","sm":"12","xs":"12"}},[_c('soi-select',{attrs:{"label":'Convênio',"items":_vm.listaConvenios,"disabled":_vm.disabled},on:{"input":_vm.mudaListaPlanos},model:{value:(_vm.pacienteConvenio.convenioId),callback:function ($$v) {_vm.$set(_vm.pacienteConvenio, "convenioId", $$v)},expression:"pacienteConvenio.convenioId"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","sm":"12","xs":"12"}},[_c('soi-select',{attrs:{"label":'Plano',"items":_vm.listaPlanos,"disabled":_vm.disabled || _vm.desabilitaPlano},model:{value:(_vm.pacienteConvenio.planoId),callback:function ($$v) {_vm.$set(_vm.pacienteConvenio, "planoId", $$v)},expression:"pacienteConvenio.planoId"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","sm":"12","xs":"12"}},[_c('soi-input',{attrs:{"name":'Número de Registro',"label":'Número de Registro',"placeholder":'Número de Registro',"disabled":_vm.disabled || _vm.desabilitaPlano},model:{value:(_vm.pacienteConvenio.numeroRegistro),callback:function ($$v) {_vm.$set(_vm.pacienteConvenio, "numeroRegistro", $$v)},expression:"pacienteConvenio.numeroRegistro"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","sm":"12","xs":"12"}},[_c('soi-input',{attrs:{"name":'Data de Vencimento',"label":'Data de Vencimento',"disabled":_vm.disabled || _vm.desabilitaPlano,"type":"date"},model:{value:(_vm.pacienteConvenio.vencimento),callback:function ($$v) {_vm.$set(_vm.pacienteConvenio, "vencimento", $$v)},expression:"pacienteConvenio.vencimento"}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }