<template>
  <v-dialog v-model="modalContato" width="800" height="600">
    <v-card class="mx-auto">
      <v-toolbar flat dark color="success">
        <v-toolbar-title>
          <v-icon>mdi mdi-map-marker</v-icon>
          Editar Contato
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="fecharModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12" xs="12">
            <v-card outlined>
              <v-card-title>Telefone</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="5" sm="12">
                    <soi-select :name="'Tipo'" :label="'Tipo'" :items="enumTipoTelefone"
                      v-model="contato.tipoTelefonePrincipal">
                    </soi-select>
                  </v-col>
                  <v-col cols="12" md="7" sm="12">
                    <soi-input :name="'Telefone'" :label="'Telefone'" v-model="contato.telefonePrincipal"
                      :mask="masks.telefone" :placeholder="'(00) 00000-0000'"></soi-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="5" sm="12">
                    <soi-select :name="'Tipo'" :label="'Tipo'" :items="enumTipoTelefone"
                      v-model="contato.tipoTelefoneSecundario">
                    </soi-select>
                  </v-col>
                  <v-col cols="12" md="7" sm="12">
                    <soi-input :name="'Telefone secundário'" :label="'Telefone secundário'"
                      v-model="contato.telefoneSecundario" :mask="masks.telefone"
                      :placeholder="'(00) 00000-0000'"></soi-input>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col xs="12">
            <v-card outlined>
              <v-card-title>Email</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="5">
                    <soi-select :name="'Tipo'" :label="'Tipo'" :items="enumTipoEmail"
                      v-model="contato.tipoEmailPrincipal">
                    </soi-select>
                  </v-col>
                  <v-col cols="12" md="7" sm="12">
                    <soi-input :name="'Email'" :label="'Email'" v-model="contato.emailPrincipal" :rules="{ email: true }"
                      :type="'email'" :placeholder="'teste@teste.com'"></soi-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="5">
                    <soi-select :name="'Tipo'" :label="'Tipo'" :items="enumTipoEmail"
                      v-model="contato.tipoEmailSecundario">
                    </soi-select>
                  </v-col>
                  <v-col cols="12" md="7" sm="12">
                    <soi-input :name="'Email secundário'" :label="'Email secundário'" v-model="contato.emailSecundario"
                      :rules="{ email: true }" :type="'email'" :placeholder="'teste@teste.com'"></soi-input>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="fecharModal()" :loading="carregando">
          <v-icon>mdi-cancel</v-icon>Cancelar</v-btn>
        <v-btn color="success" class="mr-2" @click="salvar()" :loading="carregando" :disabled="carregando">
          <v-icon>mdi mdi-content-save-check</v-icon> Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Pacientes from '@/models/pacientes/Pacientes';
import { PacientesService } from '@/services/PacientesService';
import MaskHelper from '@/helpers/MaskHelper';
import { MensagemMixin } from '@/helpers/MensagemHelper';
import Contato from '@/models/objetosDeValor/Contato';
import { TipoTelefone, TipoEmail } from "@/models/Enums";

export default Vue.extend({
  name: 'soi-modal-contato',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalContato: false,
      carregando: false,
      paciente: new Pacientes(),
      contato: new Contato(),
      service: new PacientesService(),
      responseOk: false,
      masks: MaskHelper,
      enumTipoTelefone: TipoTelefone,
      enumTipoEmail: TipoEmail,
    }
  },
  watch: {
    value() {
      this.paciente = this.value;
    }
  },
  methods: {
    fecharModal() {
      this.modalContato = false;
    },

    abrirModal() {
      this.modalContato = true;
      setTimeout(() => {
        this.contato = Object.assign({}, this.paciente.contato);
      }, 10);
    },

    async salvar() {
      this.carregando = true;
      this.paciente.contato = this.contato;

      await this.service.Patch(this.paciente.id, this.paciente).then(
        (res) => {
          MensagemMixin(
            3000,
            "success",
            "Contato do paciente editado com sucesso."
          );
          this.responseOk = true;
          this.fecharModal();
          this.carregando = false;
        },
        (err) => {
          this.carregando = false;
          MensagemMixin(3000, "error", err);
          this.responseOk = false;
        }
      );
    },
  }
});
</script>