<template>
    <v-container>
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="12" md="12" class=" d-flex justify-md-center">
                    <v-avatar class="avatar text-center" style="align-self: start;" color="success" size="10rem">
                        <img v-if="urlImagem" :src="urlImagem" />
                        <v-icon v-else dark size="150">mdi-account</v-icon>
                    </v-avatar>
                </v-col>
            </v-row>
            <v-container>
                <v-row class=" d-flex justify-center">
                    <v-col cols="12" sm="12" md="4">
                        <!-- Nome do Paciente -->
                        <v-row >
                            <v-col class="d-flex justify-center">
                                <h2>{{ paciente.nome + " " + paciente.sobrenome }}</h2>
                            </v-col>
                        </v-row>

                        <!-- Idade -->
                        <v-row >
                            <v-col class="d-flex justify-center" >
                                <h3>Idade: {{ idadePaciente }}</h3>
                            </v-col>
                        </v-row>

                        <!-- Endereço -->
                        <v-list two-line class="ml-6">
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="success">mdi-map-marker</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content v-if="this.paciente.endereco.cep !== null">
                                    <v-list-item-title>
                                        {{
                                            this.paciente.endereco.logradouro
                                                ? this.paciente.endereco.logradouro
                                        : "Logradouro não informado"
                                        }}
                                        {{
                                            this.paciente.endereco.numero
                                                ? this.paciente.endereco.numero
                                        : ""
                                        }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{
                                            this.paciente.endereco.complemento
                                                ? this.paciente.endereco.complemento
                                        : "Complemento não informado"
                                        }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="this.paciente.endereco.cep !== null">
                                        {{
                                            this.paciente.endereco.bairro
                                                ? this.paciente.endereco.bairro
                                        : "Bairro não informado"
                                        }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="this.paciente.endereco.cep !== null">
                                        {{
                                            this.paciente.endereco.cep +
                                            ` ` +
                                            this.paciente.endereco.cidade +
                                        ` - ` +
                                        retornaEstadoPorExtenso
                                        }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-content v-else>
                                    <v-list-item-title>Endereço</v-list-item-title>
                                    <v-list-item-subtitle>
                                        Nenhum registro encontrado.
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-icon>
                                    <soi-modal-endereco ref="modalEditarEndereco"
                                        :value="this.paciente"></soi-modal-endereco>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>

                        <v-divider inset></v-divider>

                        <!-- Telefone -->
                        <v-list two-line class="ml-6">
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="success">mdi-phone</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content v-if="this.paciente.contato.telefonePrincipal !== null">
                                    <v-list-item-title>
                                        {{ this.paciente.contato.telefonePrincipal }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ retornaTipoTelefonePrincipalPorExtenso }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content v-else>
                                    <v-list-item-title>Telefone</v-list-item-title>
                                    <v-list-item-subtitle>
                                        Nenhum registro encontrado.
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <soi-modal-contato ref="modalEditarContato"
                                        :value="this.paciente"></soi-modal-contato>
                                </v-list-item-icon>
                            </v-list-item>

                            <!-- Telefone Secundário -->
                            <v-list-item v-if="this.paciente.contato.telefoneSecundario !== null">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ this.paciente.contato.telefoneSecundario }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ retornaTipoTelefoneSecundarioPorExtenso }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider inset></v-divider>

                            <!-- E-mail -->
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="success">mdi-email</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content v-if="this.paciente.contato.emailPrincipal !== null">
                                    <v-list-item-title>
                                        {{ this.paciente.contato.emailPrincipal }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ retornaTipoEmailPrincipalPorExtenso }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content v-else>
                                    <v-list-item-title>E-mail</v-list-item-title>
                                    <v-list-item-subtitle>
                                        Nenhum registro encontrado.
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <!-- E-mail Secundário -->
                            <v-list-item v-if="this.paciente.contato.emailSecundario !== null">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ this.paciente.contato.emailSecundario }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ retornaTipoEmailSecundarioPorExtenso }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>


            <v-container>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12">
                        <v-card-title>Informações</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="12" md="6">
                                    <soi-input :disabled="disabled" type="number" :name="'Temperatura(°C)'"
                                        v-model="triagem.temperatura" :label="'Temperatura(°C)'"></soi-input>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <soi-input :disabled="disabled" type="number" :name="'Peso(kg)'"
                                        v-model="triagem.peso" :label="'Peso (kg)'"></soi-input>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <soi-input :disabled="disabled" type="number" :name="'Pressão Arterial(mmHg)'"
                                        :label="'Pressão Arterial(mmHg)'" v-model="triagem.pressaoArterial"></soi-input>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <soi-input :disabled="disabled" type="number" :name="'Frequência Cardiaca(BPM)'"
                                        :label="'Frequência Cardiaca(BPM)'"
                                        v-model="triagem.frequenciaCardiaca"></soi-input>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <soi-input :disabled="disabled" type="number" :name="'Frequência Respiratoria(mrm)'"
                                        :label="'Frequência Respiratoria(mrm)'"
                                        v-model="triagem.frequenciaRespiratoria"></soi-input>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-container>


            <v-container class="grey lighten-5">
                <v-col sm="12">
                    <v-row no-gutters class="justify-center">
                        <v-col cols="12" sm="12" md="12">
                            <v-card-text>
                                <v-row>
                                    <v-col xs="12" sm="12" md="3"
                                        class="d-flex flex-column align-center justify-center">
                                        <v-avatar color="blue lighten-1" size="4rem" class="large" id="avatar-1"
                                            :class="{ 'pulse-avatar': moverMouse === 1 }" @mouseover="startPulsing(1)"
                                            @mouseout="stopPulsing(1)">
                                            <span style="font-size: 2rem;">{{ getEmoji(1) }}</span>
                                        </v-avatar>
                                        <div class="button-group">
                                            <v-btn class="ma-1 mx-1 blue lighten-1 number-btn" fab small>0</v-btn>
                                        </div>
                                        <v-btn class="ma-1 mx-1 blue lighten-1 rounded number-btn fab" small>Sem
                                            dor</v-btn>
                                        <v-btn class="ma-1 mx-3 blue lighten-1 rounded number-btn fab" small>Atendimento
                                            até 240 minutos</v-btn>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="3"
                                        class="d-flex flex-column align-center justify-center">
                                        <v-avatar color="green" size="4rem" class="large" id="avatar-2"
                                            :class="{ 'pulse-avatar': moverMouse === 2 }" @mouseover="startPulsing(2)"
                                            @mouseout="stopPulsing(2)">
                                            <span style="font-size: 2rem;">{{ getEmoji(2) }}</span>
                                        </v-avatar>
                                        <div class="button-group">
                                            <v-btn class="ma-1 mx-1 green number-btn" fab small>1</v-btn>
                                            <v-btn class="ma-1 mx-1 green -2 number-btn" fab small>2</v-btn>
                                            <v-btn class="ma-1 mx-1 green darken-4 number-btn" fab small>3</v-btn>
                                        </div>
                                        <v-btn class="ma-1 mx-1 green rounded number-btn fab" small>Dor leve</v-btn>
                                        <v-btn class="ma-1 mx-3 green rounded number-btn fab" small>Atendimento até 120
                                            minutos</v-btn>
                                    </v-col>

                                    <v-col xs="12" sm="12" md="3"
                                        class="d-flex flex-column align-center justify-center ">
                                        <v-avatar color="yellow darken-1" size="4rem" class="large" id="avatar-3"
                                            :class="{ 'pulse-avatar': moverMouse === 3 }" @mouseover="startPulsing(3)"
                                            @mouseout="stopPulsing(3)">
                                            <span style="font-size: 2rem;">{{ getEmoji(3) }}</span>
                                        </v-avatar>
                                        <div class="button-group">
                                            <v-btn class="ma-1 mx-1 yellow lighten-1 number-btn" fab small>4</v-btn>
                                            <v-btn class="ma-1 mx-1 yellow darken-1 number-btn" fab small>5</v-btn>
                                            <v-btn class="ma-1 mx-1 yellow darken-2 number-btn" fab small>6</v-btn>
                                            <v-btn class="ma-1 mx-0 orange darken-2 number-btn" fab small>7</v-btn>
                                        </div>
                                        <v-btn class="ma-1 mx-1 yellow lighten-1 rounded number-btn fab" small>Dor
                                            moderada</v-btn>
                                        <v-btn class="ma-1 mx-1 yellow lighten-1 rounded number-btn fab"
                                            small>Atendimento
                                            até 60 minutos</v-btn>
                                    </v-col>

                                    <v-col xs="12" sm="12" md="3"
                                        class="d-flex flex-column align-center justify-center">
                                        <v-avatar color="red darken-2" size="4rem" class="large" id="avatar-4"
                                            :class="{ 'pulse-avatar': moverMouse === 4 }" @mouseover="startPulsing(4)"
                                            @mouseout="stopPulsing(4)">
                                            <span style="font-size: 2rem;">{{ getEmoji(4) }}</span>
                                        </v-avatar>
                                        <div class="button-group">
                                            <v-btn class="ma-1 mx-2 red lighten-1 number-btn" fab small>8</v-btn>
                                            <v-btn class="mx-1 red darken-2 number-btn" fab small>9</v-btn>
                                            <v-btn class="mx-1 red darken-4 number-btn" fab small>10</v-btn>
                                        </div>
                                        <v-btn class="ma-1 mx-1 red darken-2 rounded number-btn" small>Dor
                                            intensa</v-btn>
                                        <v-btn class="ma-1 mx-1 red darken-2 rounded number-btn" small>Atendimento
                                            imediato</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-col>
            </v-container>

            <v-container>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-card-title>Perguntas</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <p class="perguntas">Quais são seus sintomas principais ?</p>
                                    <VueEditor :disabled="disabled" v-model="triagem.sintomaPrincipal">

                                    </VueEditor>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <p class="perguntas">Quando os sintomas começaram?</p>
                                    <VueEditor v-model="triagem.dataSintomaPrincipal" :disabled="disabled">

                                    </VueEditor>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <p class="perguntas">Você tem alguma condição médica pré-existente ou doença
                                        crônica?</p>
                                    <v-radio-group row v-model="triagem.opcaoCondicaoPreExistente" :disabled="disabled">
                                        <v-radio label="Sim" :value="true"></v-radio>
                                        <v-radio label="Não" :value="false"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="triagem.opcaoCondicaoPreExistente === true">
                                    <p class="perguntas">Quais condições pré-existente ou de doença crônica?</p>
                                    <VueEditor v-model="triagem.doencaCronica" :disabled="disabled">
                                    </VueEditor>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <p class="perguntas">Algum membro da família já teve problemas cardíacos,
                                        pulmonares, diabetes, câncer ou outras doenças graves?</p>
                                    <v-radio-group v-model="triagem.historicoFamiliar" row :disabled="disabled">
                                        <v-radio label="Sim" :value="true"></v-radio>
                                        <v-radio label="Não" :value="false"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <p class="perguntas">Está atualmente tomando algum medicamento?</p>
                                    <v-radio-group row v-model="triagem.opcaoAlgumaMedicacao" :disabled="disabled">
                                        <v-radio label="Sim" :value="true"></v-radio>
                                        <v-radio label="Não" :value="false"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="triagem.opcaoAlgumaMedicacao === true">
                                    <p class="perguntas">Quais são eles?</p>
                                    <VueEditor v-model="triagem.algumaMedicacao" :disabled="disabled">
                                    </VueEditor>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <p class="perguntas">Já foi diagnosticado com alguma alergia a medicamentos,
                                        alimentos ou outras substâncias?</p>
                                    <v-radio-group row v-model="triagem.opcaoHistoricoAlergia" :disabled="disabled">
                                        <v-radio label="Sim" :value="true"></v-radio>
                                        <v-radio label="Não" :value="false"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="triagem.opcaoHistoricoAlergia === true">
                                    <p class="perguntas">Quais são as alergias a medicamentos e alimentos e outras
                                        substâncias?</p>
                                    <VueEditor v-model="triagem.historicoAlergia" :disabled="disabled">
                                    </VueEditor>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <p class="perguntas">Pratica algum tipo de Atividade Física?</p>
                                    <v-radio-group row v-model="triagem.atividadeFisica" :disabled="disabled">
                                        <v-radio label="Sim" :value="true"></v-radio>
                                        <v-radio label="Não" :value="false"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <p class="perguntas">Possui uma boa alimentação?</p>
                                    <v-radio-group row v-model="triagem.boaAlimentacao" :disabled="disabled">
                                        <v-radio label="Sim" :value="true"></v-radio>
                                        <v-radio label="Não" :value="false"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions v-if="mostrarBotoes">
            <v-spacer></v-spacer>
            <v-btn @click="voltar()" color="warning" :disabled="clickSalvar">
                <v-icon left>mdi-keyboard-return</v-icon> Voltar
            </v-btn>
            <v-btn @click="salvartriagemESair" color="success" :disabled="clickVoltar">
                <v-icon left v-if="!clickVoltar">mdi-checkbox-marked-circle</v-icon>
                {{ clickVoltar ? 'Salvando...' : 'Salvar' }}
            </v-btn>
        </v-card-actions>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';

import { AlertaSimples, AlertaSimplesErro, MensagemMixin } from '../../helpers/MensagemHelper';
import Pacientes from '../../models/pacientes/Pacientes';
import DateHelper from '../../helpers/DateHelper';
import { Estado, TipoEmail, TipoTelefone } from '../../models/Enums';
import { VueEditor } from "vue2-editor";
import Triagem from '../../models/triagem/Triagem';
import { PacientesService } from '../../services/PacientesService';
import NavigationHelper from '../../helpers/NavigationHelper';
import { TriagemService } from '../../services/TriagemService';

export default Vue.component('soi-dados-padrao-triagem', {
    components: {
        VueEditor,
    },
    props: {
        rotaModalAgendamento: {
            type: String
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        mostrarBotoes: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            responseOk: false,
            moverMouse: null,
            triagem: new Triagem(),
            triagemService: new TriagemService(),
            paciente: new Pacientes(),
            urlImagem: '',
            opcaoSelecionada: "",
            opcaoSelecionada3: "",
            opcaoSelecionada4: "",
            opcaoSelecionada5: "",
            emojin1: '😃',
            emojin2: '😐',
            emojin3: '☹️',
            emojin4: '😣',
            pacienteService: new PacientesService(),
            carregando: false,
            clickSalvar: false,
            clickVoltar: false,

        }
    },

    watch: {
        'paciente.foto'() {
            if (this.paciente.foto !== null && this.paciente.foto !== '')
                this.urlImagem =
                    'https://medsystemcloud-new.s3.us-west-2.amazonaws.com/' +
                    this.paciente.foto;
        },
        value() {
            this.triagem = this.value;
        }
    },

    mounted: function () {
        this.$nextTick(async function () {
            if (this.$route.name === "triagensForm") {
                this.triagem = new Triagem();
                this.triagem.agendamentoId = parseInt(this.$route.params.agendamentoId);
                await this.carregarPaciente(parseInt(this.$route.params.pacienteId), parseInt(this.$route.params.agendamentoId));

            }
        })

    },

    methods: {
        async carregarPaciente(idPaciente: number) {
            this.paciente = new Pacientes();
            this.urlImagem = '';
            if (idPaciente) {
                this.triagem.pacienteId = idPaciente;
                await this.pacienteService.Get(idPaciente).then(
                    (res) => {
                        this.paciente = res.data;
                        this.paciente.dataNascimento = DateHelper.formataParaAnoMesDia(
                            this.paciente.dataNascimento
                        );
                        this.idade = DateHelper.calculaIdadePorExtenso(
                            this.paciente.dataNascimento
                        );
                    },
                    (err) => {
                        AlertaSimplesErro("Aviso:", err);
                    }
                );
            } else {
                this.paciente = new Pacientes();
            }
        },
        async carregarTriagem(idAgendamento: number) {
            this.triagem = new Triagem();

            if (idAgendamento) {
                await this.triagemService.GetTriagemPorAgendamentoId(idAgendamento).then(
                    (res) => {
                        this.triagem = res.data;
                        (err) => {

                        }
                    });
            }
        },

        async carregarTriagemNoAtendimento(idAgendamento: number, idPaciente: number) {
            await this.carregarTriagem(idAgendamento);
            await this.carregarPaciente(idPaciente);
            if (this.triagem !== new Triagem()) {
                this.$emit('mostrarTriagem', true)
            }
            else {
                this.$emit('mostrarTriagem', false)
            }
        },

        async carregarTriagemNoAgendamento(idAgendamento: number, idPaciente: number) {
            this.triagem = new Triagem();
            this.triagem.agendamentoId = idAgendamento;
            await this.carregarPaciente(idPaciente);
        },


        async salvartriagemESair() {
            if (!this.clickVoltar) {
                this.clickSalvar = true;
                this.clickVoltar = true;
                var salvoComSucesso = await this.salvarTriagem();
                if (salvoComSucesso) {
                    this.$router.push({
                        name: 'triagens',
                    });
                }
            }
        },
        voltar() {
            if (!this.clickSalvar) {
                NavigationHelper.navigate("triagens");

            }
        },



        async salvarTriagem() {
            try {
                this.carregando = true;
                const res = await this.triagemService.Post(this.triagem);
                const idTriagem = res.data.id;
                MensagemMixin(3000, 'success', 'Triagem salva com sucesso.');
                this.idTriagem = idTriagem;

                this.responseOk = true;
                if (this.rotaModalAgendamento === 'ModalAgendamento') {
                    this.$emit('atualizaCalendario');
                } else {
                    this.$router.push({
                        name: 'triagens',
                    });
                }

                this.$emit('triagemSalva');


                this.carregando = false;
            } catch (err) {
                AlertaSimplesErro('Aviso:', err);
                this.responseOk = false;
                this.carregando = false;
            }
            finally {
                this.clickSalvar = false;
                this.clickVoltar = false;
            }
        },

        apagarFoto() {
            this.paciente.foto = ''
        },

        startPulsing(avatar) {
            this.moverMouse = avatar;
        },

        stopPulsing(avatar) {
            this.moverMouse = null;
        },

        getEmoji(avatar) {
            switch (avatar) {
                case 1:
                    return this.moverMouse === 1 ? '😄' : this.emojin1;
                case 2:
                    return this.moverMouse === 2 ? '😕' : this.emojin2;
                case 3:
                    return this.moverMouse === 3 ? '😟' : this.emojin3;

                case 4:
                    return this.moverMouse === 4 ? '😖' : this.emojin4;
                default:
                    return '';
            }
        },
    },


    computed: {

        subTitulo() {
            return this.visualizar ? "Visualizar Triagem" : this.idPaciente ? "Triagem Do Paciente" : "";
        },

        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        idadePaciente() {
            return DateHelper.calculaIdadePorExtenso(
                this.paciente.dataNascimento
            );
        },
        retornaEstadoPorExtenso() {
            var estadoPorExtenso = Estado.find(
                (x) => x.value == this.paciente.endereco.estado,
            );

            return estadoPorExtenso ? estadoPorExtenso.text : '';
        },
        retornaTipoTelefonePrincipalPorExtenso() {
            var tipoTelefonePorExtenso = TipoTelefone.find(
                (x) => x.value == this.paciente.contato.tipoTelefonePrincipal
            );

            return tipoTelefonePorExtenso ? tipoTelefonePorExtenso.text : '';
        },
        retornaTipoTelefoneSecundarioPorExtenso() {
            var tipoTelefonePorExtenso = TipoTelefone.find(
                (x) => x.value == this.paciente.contato.tipoTelefoneSecundario
            );

            return tipoTelefonePorExtenso ? tipoTelefonePorExtenso.text : '';
        },
        retornaTipoEmailPrincipalPorExtenso() {
            var tipoEmailPorExtenso = TipoEmail.find(
                (x) => x.value == this.paciente.contato.tipoEmailPrincipal
            );

            return tipoEmailPorExtenso ? tipoEmailPorExtenso.text : '';
        },
        retornaTipoEmailSecundarioPorExtenso() {
            var tipoEmailPorExtenso = TipoEmail.find(
                (x) => x.value == this.paciente.contato.tipoEmailSecundario
            );

            return tipoEmailPorExtenso ? tipoEmailPorExtenso.text : '';
        },
    },


},
)
</script>
<style>
.perguntas {
    text-align: left;
    font-weight: bold;
    color: black;
    font-size: 16px;
}


@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.pulse-avatar {
    animation: pulse 1.5s infinite;
    cursor: pointer;
}
</style>