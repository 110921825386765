import Pacientes from '@/models/pacientes/Pacientes';
import  http  from './ApiConfig';
import { Service } from './Service';

export class PacientesService extends Service {

    constructor(){
        super('pacientes');
    }

    public GetAllPaginado(nomeId: string,dataNascimento: string, cpf: string, filtroSituacaoPaciente: number, limit: number, page: number) {
        return http.get(`${this._nomeControle}?nomeId=${nomeId}&dataNascimento=${dataNascimento}&cpf=${cpf}&filtroSituacaoPaciente=${filtroSituacaoPaciente}&limit=${limit}&page=${page}`, this.GetHeader());
    }

    public BuscarPaciente() {
        return http.get(`${this._nomeControle}/buscar`, this.GetHeader());
    }    

    public AtivarPaciente(pacienteId: number) {
        return http.patch(`${this._nomeControle}/${pacienteId}/ativar`, this.GetHeader());
    }  
    
    public InativarPaciente(pacienteId: number) {
        return http.patch(`${this._nomeControle}/${pacienteId}/inativar`, this.GetHeader());
    }  
}
