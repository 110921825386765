<template>
    <div>       
        <v-text-field
          outlined
          dense
          disabled
          color="success"          
          :label="label"
          :prepend-icon="prependIcon"
          v-model="text"   
          :hint="hint"
          persistent-hint       
        >
          <template v-slot:append>
            <v-icon color="success" @click="abrirModal" :disabled="disabled">
              mdi-magnify
            </v-icon>
          </template>          
        </v-text-field>        
        <v-dialog
            transition="dialog-bottom-transition"
            v-model="modalPicker"
            max-width="1000px"        
        >
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <v-form>
              <v-card class="elevation-0">
                <v-toolbar flat outlined>
                    <v-toolbar-title>
                        <v-icon color="success">{{ icon }}</v-icon>
                        {{title}}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="fecharModal()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-5">              
                  <v-row>                                 
                    <v-col cols="12" md="12" sm="12" :class="{ 'hide-scrollbar': itemsPerPage === 5 }"
                            style="max-height: 600px; overflow-y: auto;">                       
                        <v-data-table                            
                            :loading="carregando ? 'success' : null"                     
                            :headers="headers"
                            :items="items"
                            :items-per-page="itemsPerPage"                                               
                            :search="filtro"
                            :ref="tableRef"
                            :single-select="singleSelect"   
                            :page.sync="page"         
                            hide-default-footer
                            @page-count="pageCount = $event"              
                            item-key="id"
                            v-model="selected"
                            class="elevation-1"                                   
                            no-data-text="Nenhum registro encontrado!"
                            loading-text="Carregando os itens desta tabela! Aguarde..."
                            no-results-text="Nenhum item encontrado com os termos pesquisados."                              
                            show-select
                        >
                            <template v-slot:top>
                                <v-card class="elevation-0">
                                    <v-card-text>
                                        <v-row cols="12">
                                            <v-col sm="10" md="10">                                                
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    success
                                                    v-model="filtro"
                                                    :append-icon="filtro ? 'mdi-close' : 'mdi-magnify'"
                                                    @click:append="filtro ? filtro = '' : null"
                                                    label="Digite algo para começar a pesquisar"                                                  
                                                ></v-text-field>
                                            </v-col>     
                                            <v-col sm="2" md="2">
                                                <v-select
                                                    outlined
                                                    dense
                                                    color="success"
                                                    :value="itemsPerPage"
                                                    @input="itemsPerPage = parseInt($event, 10)"
                                                    label="Qtde. Itens"     
                                                    :items="itemsPerPagination"                                  
                                                ></v-select>
                                            </v-col>                        
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </template>    
                            <template v-slot:item.actions="{ item }">
                                <v-btn
                                    v-for="(button, index) in actions"
                                    :key="index"
                                    class="mx-2"
                                    x-small
                                    fab
                                    :title="button.label"
                                    :color="button.color"
                                    @click="handleAction(button.action, item)"
                                >
                                    <v-icon>{{ button.icon }}</v-icon>
                                </v-btn>
                            </template>                                 
                        </v-data-table>  
                        <v-pagination
                            v-model="page"
                            :length="pageCount"
                            :total-visible="7"
                            color="success"
                            class="mt-4"
                        ></v-pagination>                                           
                    </v-col>                   
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="select()" :loading="carregando" :disabled="carregando">
                        <v-icon>mdi-plus</v-icon>
                        Selecionar
                    </v-btn>
                    <v-btn color="error" @click="fecharModal()">
                        <v-icon>mdi-cancel</v-icon>
                        Cancelar
                    </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </validation-observer>
        </v-dialog>
    </div>   
</template>

<script lang="ts">
import { MensagemMixin } from '@/helpers/MensagemHelper';
import Vue from 'vue';

export default Vue.component("soi-picker", {    
    props: {       
        label: {
            type: String,
            required: true,
            default: "Selecione um item..."
        },
        prependIcon: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: true,
            default: false
        },
        value: {},       
        rules: {
            type: Object,
            default() {
                return {}
            },
            required: false
        },
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true,            
        },
        headers: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        singleSelect: {
            type: Boolean,
            required: true,
            default: true,
        },      
        hint: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        actions: {
            type: Array,
            default: () => [],
        }
    },
    watch: {
        value: function () {
            this.$nextTick(async function () {
                this.text = this.value;
            })            
        }
    },      
    data() {
        return {            
            modalPicker: false,
            filtro: '',            
            text: '',
            selected: [],
            itemsPerPage: 5,
            carregando: false,       
            tableRef: `table-picker-${this.name}`,
            page: 1,
            pageCount: 0, 
            itemsPerPagination: [
                { value: 5, text: 5 },
                { value: 10, text: 10 },
                { value: 15, text: 15 },
            ],               
        }
    },        
    methods: {          
        abrirModal() {
            this.filtro = '';
            this.page = 1;           
            this.modalPicker = true;
            this.$emit('update');            
        },
        fecharModal() {
            this.filtro = '';
            this.selected = [];
            this.modalPicker = false;  
            this.page = 1;                            
        },       
        select() {
            const selectedItems = this.selected;

            if (selectedItems.length === 0) {
                MensagemMixin(3000, 'error', `É necessário selecionar pelo menos um(a) ${this.label ? this.label : this.title}.`);
            } else {               
                this.modalPicker = false;                
                this.$emit('change', selectedItems);
            }
        },
        handleAction(action, item) {
            this.$emit(action, item);
        },
    }
});
</script>