import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VAppBar,{attrs:{"app":"","absolute":"","elevation":"0"}},[_c(VToolbarTitle,{staticClass:"titulo"},[_vm._v(_vm._s(_vm.titulo))]),_c(VSpacer),_c('div',{staticClass:"menu-user"},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"user-container"},'div',attrs,false),on),[_c(VAvatar,{staticClass:"white--text text-h5 success darken-2",attrs:{"size":"48"}},[(_vm.user && _vm.user.profilePicture)?_c('img',{attrs:{"src":'https://medsystemcloud-new.s3.us-west-2.amazonaws.com/' +
        _vm.user.profilePicture}}):(_vm.user && _vm.user.role === 'Host')?_c('img',{attrs:{"src":require("../assets/images/logo.jpeg")}}):_c('span',[_vm._v(" "+_vm._s(_vm.formatarIniciaisNomeSobrenome(_vm.user.name, _vm.user.sobrenome))+" ")])]),_c('div',{staticClass:"user-info"},[_c('span',[_vm._v(_vm._s(_vm.user.name))]),_c('h5',[_vm._v(_vm._s(_vm.user.role == 'Admin' ? 'Administrador Master' : _vm.user.role))])]),_c(VSpacer),_c(VIcon,[_vm._v(_vm._s(_vm.menuOpen ? 'mdi-menu-up' : 'mdi-menu-down'))])],1)]}}]),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[_c(VList,{staticClass:"menu-list"},[(_vm.user && _vm.user.role === 'Admin')?_c(VListItem,{staticClass:"menu-item",on:{"click":function($event){return _vm.$router.push({ name: 'clinicasFormUpdate' })}}},[_c(VIcon,[_vm._v("$clinical")]),_c('span',[_vm._v("Clínica")])],1):_vm._e(),(_vm.user.role === 'Host')?_c(VListItem,{staticClass:"menu-item",on:{"click":function($event){return _vm.$router.push({ name: 'clinicasFormUpdate' })}}},[_c(VIcon,[_vm._v("$clinical")]),_c('span',[_vm._v("Criar Clínica")])],1):_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'perfilDoUsuario' })}}},[_c(VIcon,[_vm._v("$profile")]),_c('span',[_vm._v("Perfil")])],1),_c(VListItem,{on:{"click":function($event){return _vm.alterarTemaDoSistema()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.modoNoturno ? '$lightMode' : '$darkMode'))]),_c('span',[_vm._v(" "+_vm._s(_vm.modoNoturno ? "Modo Claro" : "Modo Escuro"))])],1),_c(VListItem,{on:{"click":function($event){return _vm.sair()}}},[_c(VIcon,[_vm._v("$logout")]),_c('span',[_vm._v("Sair")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }