import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"width":"800"},model:{value:(_vm.modalAberto),callback:function ($$v) {_vm.modalAberto=$$v},expression:"modalAberto"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_c(VIcon,{attrs:{"left":"","color":"success"}},[_vm._v("mdi-note")]),_vm._v("Nota do dia ")],1),_c(VCardText,[_c(VTextarea,{staticClass:"mt-4",attrs:{"outlined":"","name":"input-7-1","label":"Descrição","color":"success"},model:{value:(_vm.notaDia.descricao),callback:function ($$v) {_vm.$set(_vm.notaDia, "descricao", $$v)},expression:"notaDia.descricao"}})],1),_c(VCardActions,[_c(VRow,[_c(VCol,{attrs:{"align":"end"}},[_c(VBtn,{class:{'mb-2' : _vm.$vuetify.breakpoint.name == 'xs'},attrs:{"block":_vm.$vuetify.breakpoint.name == 'xs',"color":"error","disabled":_vm.notaDia.descricao === '' || !_vm.notaDiaExistente},on:{"click":function($event){return _vm.excluir()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-minus-circle")]),_vm._v("Excluir ")],1),_c(VBtn,{class:{'mb-2' : _vm.$vuetify.breakpoint.name == 'xs', 'ml-2' : _vm.$vuetify.breakpoint.name != 'xs'},attrs:{"block":_vm.$vuetify.breakpoint.name == 'xs',"color":"error"},on:{"click":function($event){_vm.modalAberto = false}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v("Cancelar ")],1),_c(VBtn,{class:{'mb-2' : _vm.$vuetify.breakpoint.name == 'xs', 'ml-2' : _vm.$vuetify.breakpoint.name != 'xs'},attrs:{"block":_vm.$vuetify.breakpoint.name == 'xs',"color":"success"},on:{"click":function($event){return _vm.salvar()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-checkbox-marked-circle")]),_vm._v(" Salvar ")],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }