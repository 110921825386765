<template>
    <v-dialog v-model="modalPesquisarMedicamento" @atualizar="atualizar" width="1200" height="800">
        <v-card class="mx-auto" :loading="carregando ? 'success' : null" :disabled="carregando">
            <v-toolbar flat outlined>
                <v-toolbar-title>
                    <v-icon>mdi mdi-pill</v-icon>
                    Visualizar Medicamentos
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="fecharModal()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <div class="table-container" :class="{ 'hide-scrollbar': itens === 5 }"
                    style="max-height: 400px; overflow-y: auto;">
                    <v-data-table :headers="colunas" :no-data-text="'Nenhum medicamento encontrado!'"
                        :loading="carregando ? 'success' : null" loading-text="Carregando os itens desta tabela! Aguarde..."
                        :items-per-page="itens" :items="medicamentos" :page.sync="paginaAtual" :search="valorFiltro"
                        :header-props="headerProps" hide-default-footer>
                        <template v-slot:top>
                            <v-card class="elevation-0 sticky-header">
                                <v-card-text>
                                    <v-row cols="12">
                                        <v-col sm="4" md="4">
                                            <v-radio-group v-model="tipoFiltro" row>
                                                <v-radio color="success" class="radioFlex" v-for="itens in opcoesFiltro"
                                                    :label="itens.name" :value="itens.key" :key="itens.key">
                                                </v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col sm="6" md="6">
                                            <v-text-field :type="tipoFiltroComputado" outlined dense color="success"
                                                v-model="valorFiltro" @input="listar()" append-icon="mdi-magnify"
                                                :label="labelFiltro" class="mt-4">
                                            </v-text-field>
                                        </v-col>
                                        <v-col sm="2" md="2">
                                            <v-select outlined dense color="success" v-model="itens" label="Qtde.Itens"
                                                :items="quantidadeItens" @input="listar()" class="mt-4"></v-select>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </template>
                        <template v-slot:item.nome="{ item }">
                            <div class="cell-break">{{ item.nome }}</div>
                        </template>
                        <template v-slot:item.principioAtivo="{ item }">
                            <div class="cell-break">{{ item.principioAtivo }}</div>
                        </template>                        
                        <template v-slot:item.actions="{ item }">
                            <v-btn x-small fab class="mx-2" color="primary" title="Acessar Bula"
                                v-if="medicamentosSistema" @click="acessarBula(item.anvisa)">                              
                                <v-icon>mdi mdi-open-in-new</v-icon>
                            </v-btn>                                
                            <v-btn x-small fab class="mx-2" color="warning" title="Editar Medicamento"
                                @click="editarMedicamento(item)"
                                v-if="!medicamentosSistema"><v-icon>mdi-pencil</v-icon></v-btn>
                            <v-btn x-small fab class="mx-2" color="error" title="Excluir Medicamento"
                                @click="excluir(item.id)" v-if="!medicamentosSistema"><v-icon>mdi-delete</v-icon></v-btn>
                        </template>
                    </v-data-table>
                </div>
                <v-pagination color="success" @input="listar()" v-model="paginaAtual" :length="totalPaginas"
                    :total-visible="7" class="mt-2">
                </v-pagination>
            </v-card-text>
        </v-card>
        <soi-medicamento ref="modalMedicamento" @medicamentoEditado="listar()"></soi-medicamento>        
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { MedicamentosService } from '../../services/MedicamentosService';
import {
    AlertaExclusaoQuestaoBooleano,   
    AlertaSimples,   
    AlertaSimplesErro,
    MensagemMixin,
} from '@/helpers/MensagemHelper';
import { PDFDocument } from "pdf-lib";
import Medicamento from '@/models/medicamento/Medicamento';

export default Vue.extend({
    name: 'soi-pesquisar-medicamento',
    props: {
        medicamentosSistema: {
            type: Boolean,
            default: false,
            required: true
        },
    },
    data() {
        return {
            carregando: false,
            modalPesquisarMedicamento: false,
            service: new MedicamentosService(),
            medicamentos: Array<Medicamento>(),
            tipoFiltro: 'nomeId',
            tipoFiltroNumber: 0,
            valorFiltro: '',
            headerProps: {
                sortByText: 'Ordenar por',
            },
            quantidadeItens: [
                { value: 5, text: 5 },
                { value: 10, text: 10 },
                { value: 15, text: 15 },
            ],
            opcoesFiltro: [
                { name: 'Id ou Nome', key: 'nomeId', type: 'string' },
                {
                    name: 'Princípio Ativo',
                    key: 'principioAtivo',
                    type: 'string',
                },
            ],
            footerProps: {
                itemsPerPageOptions: [3, 5, 10, 15],
                itemsPerPageText: 'Medicamentos por página',
                pageText: '{0}-{1} de {2}',
            },
            totalPaginas: 0,
            paginaAtual: 1,
            itens: 0,
            colunas: [
                {
                    text: 'ID',
                    align: 'start',
                    value: 'id',
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Nome',                    
                    value: 'nome',
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Princípio Ativo',                    
                    value: 'principioAtivo',
                    filterable: true,
                    sortable: true,
                },               
                {
                    text: 'Ações',
                    align: 'center',
                    value: 'actions',
                },
            ],
            responseOk: false,                          
        };
    },
    computed: {
        tipoFiltroComputado() {
            return this.opcoesFiltro.find((x) => x.key == this.tipoFiltro)
                ?.type;
        },
        labelFiltro() {
            const itemSelecionado = this.opcoesFiltro.find(item => item.key === this.tipoFiltro);

            if (itemSelecionado) {
                return `Digite um ${itemSelecionado.name} para pesquisar...`;
            }

            return 'Digite o que deseja pesquisar...';
        }
    },
    mounted() {
        this.itens = 5;
        this.listar();
    },
    methods: {
        abrirModalPesquisarMedicamento() {
            this.modalPesquisarMedicamento = true;
            this.itens = 5;
            this.listar();
        },

        fecharModal() {
            this.modalPesquisarMedicamento = false;
            this.limpaPesquisa();
        },

        limpaPesquisa() {
            this.paginaAtual = 1;
            this.valorFiltro = '';
            this.listar();
        },

        pesquisa() {
            this.paginaAtual = 1;
            this.listar();
        },

        async listar() {
            this.carregando = true;

            await this.service
                .GetAllPaginado(
                    this.tipoFiltro == 'nomeId' ? this.valorFiltro : '',
                    this.tipoFiltro == 'principioAtivo' ? this.valorFiltro : '',
                    this.medicamentosSistema,
                    this.itens,
                    this.paginaAtual
                )
                .then(
                    (res) => {
                        this.medicamentos = res.data.itens;
                        this.totalPaginas = res.data.totalPaginas;
                        this.paginaAtual = res.data.paginaAtual;                   
                    },
                    (err) => {
                        
                    }
                );

            this.carregando = false;
        },

        async acessarBula(anvisa) {            
            await this.service.GetBula(anvisa)
                .then(
                    (res) => {    
                        var pdfBytes = res.data;

                        this.convertToPDF(pdfBytes);

                        this.$emit('atualizar');                               
                    },
                    (err) => {
                        MensagemMixin(5000, "error", "Erro ao buscar bula. Aguarde alguns instantes.");                      
                    }
                );
        },

        atualizar() {            
            this.$refs.modalPesquisarMedicamento.$forceUpdate();
        },

        async convertToPDF(pdfBula) {
            try {
                const pdfDoc = await PDFDocument.create();
                const existingPdfBytes = pdfBula;
                const existingPdfDoc = await PDFDocument.load(existingPdfBytes);
                const [existingPage] = await pdfDoc.copyPages(existingPdfDoc, [0]);
                pdfDoc.addPage(existingPage);

                const pdfBytes = await pdfDoc.save();

                const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });

                const pdfUrl = URL.createObjectURL(pdfBlob);

                window.open(pdfUrl, "_blank");
            } catch (error) {
                MensagemMixin(5000, "info", "Bula indisponível para o medicamento selecionado.");
            }            
        },

        async excluir(idMedicamento: number) {
            if (await AlertaExclusaoQuestaoBooleano()) {
                this.service.Delete(idMedicamento).then(
                    (res) => {
                        this.listar();
                        MensagemMixin(
                            3000,
                            'success',
                            'Medicamento deletado com sucesso.'
                        );
                    },
                    (err) => {
                        AlertaSimplesErro('Aviso:', err);
                    }
                );
            }
        },

        editarMedicamento(item) {
            this.$refs.modalMedicamento.abrirModalMedicamento(item);
        },
    },
});
</script>
<style scoped>
.cell-break {
    word-wrap: break-word;
    max-width: 300px;
}

.table-container.hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.table-container.hide-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.sticky-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
}
</style>