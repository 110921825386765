import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"transition":"dialog-bottom-transition","max-width":"900","persistent":""},model:{value:(_vm.modalAberto),callback:function ($$v) {_vm.modalAberto=$$v},expression:"modalAberto"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"loading":_vm.carregando ? 'success' : null,"disabled":_vm.carregando}},[_c(VToolbar,{attrs:{"flat":"","outlined":""}},[_c(VToolbarTitle,[_c(VIcon,{attrs:{"color":"success","left":"","size":"x-large"}},[_vm._v("mdi-bookmark-plus-outline")]),_vm._v(_vm._s(_vm.titulo)+" ")],1),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.fecharModal()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12","sm":"12"}},[_c(VDataTable,{staticClass:"elevation-0",attrs:{"loading":_vm.carregando ? 'success' : null,"no-data-text":"Nenhum(a) Atendimento não Finalizado encontrado(a)!","loading-text":"Carregando os itens desta tabela! Aguarde...","headers":_vm.colunasAtendimentosNaoFinalizados,"items-per-page":-1,"hide-default-footer":"","items":_vm.atendimentos},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c(VBtn,{staticClass:"mx-2",attrs:{"x-small":"","fab":"","color":"success","title":"Continuar Atendimento"},on:{"click":function($event){return _vm.continuarAtendimento(item.id)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c(VBtn,{staticClass:"mx-2",attrs:{"x-small":"","fab":"","color":"error","title":"Descartar Atendimento"},on:{"click":function($event){return _vm.excluirAtendimentoNaoFinalizado(item.id)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-minus")])],1)]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }